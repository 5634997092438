import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { DialerDetailsType, DialerListItemType, DialerType } from '../../types/dialer/dialer.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteDialerDetails,
  getDialerDetails,
  getDialersList,
  patchDialerDetails,
  postDialerDetails,
  postDialers,
} from './dialers.action';

export interface DialersState {
  dialersList: null | PaginationType<DialerListItemType>;
  dialersListFetchStatus: FETCH_STATUS;
  dialersRouteParams: RouteParamsType;
  dialerDetails: null | DialerDetailsType;
  dialerDetailsFetchStatus: FETCH_STATUS;
  dialerDetailsPostStatus: FETCH_STATUS;
  dialerDetailsPatchStatus: FETCH_STATUS;
  dialerDetailsDeleteStatus: FETCH_STATUS;
  dialersPostStatus: FETCH_STATUS;
  dialersBulk: null | Array<
    Pick<
      DialerType,
      | 'radio_id'
      | 'description'
      | 'brand'
      | 'model'
      | 'cs_account'
      | 'cs_primary_receiver_country_code'
      | 'cs_primary_receiver'
      | 'cs_secondary_receiver_country_code'
      | 'cs_secondary_receiver'
      | 'config_key'
    >
  >;
}

export const initialDialersRouteParams = {
  page: 1,
  limit: 10,
  order: '-dialer.id',
  q: '',
  status: '',
  equipment_status: '',
  warehouse_id: '',
  in_service: 'all',
  country_id: '',
  country_category: '',
  dialer_type_id: '',
};

const INIT_STATE: DialersState = {
  dialersRouteParams: initialDialersRouteParams,
  dialersList: null,
  dialersListFetchStatus: FETCH_STATUS.NULL,
  dialerDetails: null,
  dialerDetailsFetchStatus: FETCH_STATUS.NULL,
  dialerDetailsPostStatus: FETCH_STATUS.NULL,
  dialerDetailsPatchStatus: FETCH_STATUS.NULL,
  dialerDetailsDeleteStatus: FETCH_STATUS.NULL,
  dialersPostStatus: FETCH_STATUS.NULL,
  dialersBulk: null,
};

const dialersSlice = createSlice({
  name: 'dialers',
  initialState: INIT_STATE,
  reducers: {
    setDialersList: (state, action) => {
      state.dialersList = action.payload;
    },
    setDialersListFetchStatus: (state, action) => {
      state.dialersListFetchStatus = action.payload;
    },
    setDialerDetails: (state, action) => {
      state.dialerDetails = action.payload;
    },
    setDialerDetailsFetchStatus: (state, action) => {
      state.dialerDetailsFetchStatus = action.payload;
    },
    setDialerDetailsPostStatus: (state, action) => {
      state.dialerDetailsPostStatus = action.payload;
    },
    setDialerDetailsPatchStatus: (state, action) => {
      state.dialerDetailsPatchStatus = action.payload;
    },
    setDialerDetailsDeleteStatus: (state, action) => {
      state.dialerDetailsDeleteStatus = action.payload;
    },
    setDialersPostStatus: (state, action) => {
      state.dialersPostStatus = action.payload;
    },
    setDialersBulk: (state, action) => {
      state.dialersBulk = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'dialers') {
        state.dialersRouteParams = { ...state.dialersRouteParams, ...payload.data };
      }
    });

    // CREATE DIALERS DETAILS
    builder.addCase(postDialers.pending, state => {
      state.dialersPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDialers.rejected, (state, action) => {
      state.dialersPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postDialers.fulfilled, state => {
      state.dialersPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Dialers created successfully!');
      });
    });

    // CREATE DIALERS DETAILS
    builder.addCase(postDialerDetails.pending, state => {
      state.dialerDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDialerDetails.rejected, (state, action) => {
      state.dialerDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postDialerDetails.fulfilled, state => {
      state.dialerDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Dialer created successfully!');
      });
    });

    // GET DIALERS LIST
    builder.addCase(getDialersList.pending, state => {
      state.dialersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDialersList.rejected, (state, action) => {
      state.dialersListFetchStatus = FETCH_STATUS.REJECTED;
      state.dialersList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getDialersList.fulfilled, (state, action) => {
      state.dialersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.dialersList = action.payload;
    });

    // GET DIALERS DETAILS
    builder.addCase(getDialerDetails.pending, state => {
      state.dialerDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDialerDetails.rejected, (state, action) => {
      state.dialerDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.dialerDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getDialerDetails.fulfilled, (state, action) => {
      state.dialerDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.dialerDetails = action.payload;
    });

    // PATCH DIALERS DETAILS
    builder.addCase(patchDialerDetails.pending, state => {
      state.dialerDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchDialerDetails.rejected, (state, action) => {
      state.dialerDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchDialerDetails.fulfilled, state => {
      state.dialerDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Dialers updated successfully!');
      });
    });

    // DELETE DIALERS DETAILS
    builder.addCase(deleteDialerDetails.pending, state => {
      state.dialerDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteDialerDetails.rejected, (state, action) => {
      state.dialerDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteDialerDetails.fulfilled, state => {
      state.dialerDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Dialers deleted successfully!');
      });
    });
  },
});

export const {
  setDialerDetailsDeleteStatus,
  setDialerDetailsFetchStatus,
  setDialerDetailsPatchStatus,
  setDialerDetailsPostStatus,
  setDialersListFetchStatus,
  setDialersBulk,
  setDialersPostStatus,
  setDialersList,
  setDialerDetails,
} = dialersSlice.actions;

export default dialersSlice.reducer;
