import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { DIDsListItemType } from '../../types/did/did.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  getCustomerDashboardDIDsList,
  getDomain,
  getFullAsyncTaskTypesList,
  getFullATAACSProfileList,
  getFullATABaseTemplateList,
  getFullATAOrgTemplateList,
  getFullATATypeList,
  getFullAuditEntityTypes,
  getFullBucketsList,
  getFullCountriesList,
  getFullCountryCategoriesList,
  getFullDataPlanTypeList,
  getFullDialerTypesList,
  getFullEnclosureTypesList,
  getFullEquipmentGroupList,
  getFullExternalSystemList,
  getFullIssuesTrackerCodesList,
  getFullLineTypesList,
  getFullLocationList,
  getFullOrganizationList,
  getFullPBXList,
  getFullProfileList,
  getFullRouterList,
  getFullServiceList,
  getFullServiceTypeCategoryList,
  getFullServiceTypeList,
  getFullSimsList,
  getFullTCBlockList,
  getFullVerifiedEmailsList,
  getFullVerifiedNumbersList,
  getFullWarehouseList,
  getJotFormURL,
  getRouterProductNameTypesList,
  getSIMCarrierList,
} from './shared.action';

export interface SharedState {
  // CUSTOMER DASHBOARD
  customerDashboardRouteParams: RouteParamsType;
  customerDashboardDIDsList: PaginationType<DIDsListItemType> | null;
  customerDashboardDIDsListFetchStatus: FETCH_STATUS;

  // SELECT BOXES
  selectedCompany: unknown | null;
  selectedUserAccess: unknown | null;
  selectedLocation: unknown | null;
  includeSuborgs: boolean;
  selectedCountry: unknown | null;
  jotFormURL: string;
  jotFormURLFetchStatus: FETCH_STATUS;

  // OTHER SETTINGS
  domainData: unknown | null;
  domainDataFetchStatus: FETCH_STATUS;
  timezone: unknown | null;

  fullOrganizationList: unknown | null;
  fullOrganizationListFetchStatus: FETCH_STATUS;

  fullLocationList: unknown | null;
  fullLocationListFetchStatus: FETCH_STATUS;

  fullATATypeList: unknown | null;
  fullATATypeListFetchStatus: FETCH_STATUS;

  fullATAOrgTemplateList: unknown | null;
  fullATAOrgTemplateListFetchStatus: FETCH_STATUS;

  fullATABaseTemplateList: unknown | null;
  fullATABaseTemplateListFetchStatus: FETCH_STATUS;

  fullServiceTypeList: unknown | null;
  fullServiceTypeListFetchStatus: FETCH_STATUS;

  fullServiceTypeCategoryList: unknown | null;
  fullServiceTypeCategoryListFetchStatus: FETCH_STATUS;

  fullRouterProductNameList: unknown | null;
  fullRouterProductNameListFetchStatus: FETCH_STATUS;

  fullSIMCarrierList: unknown | null;
  fullSIMCarrierListFetchStatus: FETCH_STATUS;

  fullServiceList: unknown | null;
  fullServiceListFetchStatus: FETCH_STATUS;

  fullEquipmentGroupList: unknown | null;
  fullEquipmentGroupListFetchStatus: FETCH_STATUS;

  fullPBXList: unknown | null;
  fullPBXListFetchStatus: FETCH_STATUS;

  fullATAOrganizationTemplateList: unknown | null;
  fullATAOrganizationTemplateListFetchStatus: FETCH_STATUS;

  fullDIDList: unknown | null;
  fullDIDListFetchStatus: FETCH_STATUS;

  fullAsyncTaskTypesList: unknown | null;
  fullAsyncTaskTypesListFetchStatus: FETCH_STATUS;

  fullCountryCategoriesList: unknown | null;
  fullCountryCategoriesListFetchStatus: FETCH_STATUS;

  fullRouterList: unknown | null;
  fullRouterListFetchStatus: FETCH_STATUS;

  fullDialerTypesList: unknown | null;
  fullDialerTypesListFetchStatus: FETCH_STATUS;

  fullEnclosureTypesList: unknown | null;
  fullEnclosureTypesListFetchStatus: FETCH_STATUS;

  fullWarehouseList: unknown | null;
  fullWarehouseListFetchStatus: FETCH_STATUS;

  fullATAACSProfileList: unknown | null;
  fullATAACSProfileListFetchStatus: FETCH_STATUS;

  fullTCBlockList: unknown | null;
  fullTCBlockListFetchStatus: FETCH_STATUS;

  fullLineTypesList: unknown | null;
  fullLineTypesListFetchStatus: FETCH_STATUS;

  fullSimList: unknown | null;
  fullSimListFetchStatus: FETCH_STATUS;

  fullDataPlanTypeList: unknown | null;
  fullDataPlanTypeListFetchStatus: FETCH_STATUS;

  fullVerifiedNumbersList: unknown | null;
  fullVerifiedNumbersListFetchStatus: FETCH_STATUS;

  fullVerifiedEmailsList: unknown | null;
  fullVerifiedEmailsListFetchStatus: FETCH_STATUS;

  fullIssuesTrackerCodes: unknown | null;
  fullIssuesTrackerCodesFetchStatus: FETCH_STATUS;

  fullAuditEntityTypes: unknown | null;
  fullAuditEntityTypesFetchStatus: FETCH_STATUS;

  fullExternalSystemList: unknown | null;
  fullExternalSystemListFetchStatus: FETCH_STATUS;

  fullExternalProviderList: unknown | null;
  fullExternalProviderListFetchStatus: FETCH_STATUS;

  fullBucketsList: unknown | null;
  fullBucketsListFetchStatus: FETCH_STATUS;

  fullProfileList: unknown | null;
  fullProfileListFetchStatus: FETCH_STATUS;

  fullCountriesList: unknown | null;
  fullCountriesListFetchStatus: FETCH_STATUS;

  fullGroupedCountriesList: unknown | null;
  fullGroupedCountriesListFetchStatus: FETCH_STATUS;

  cachedCountriesByOrganizationID: unknown | null;
  cachedCountriesByOrganizationIDFetchStatus: FETCH_STATUS;

  cachedGroupedCountriesByOrganizationID: unknown | null;
  cachedGroupedCountriesByOrganizationIDFetchStatus: FETCH_STATUS;
}

export const initialCustomerDashboardRouteParams = {
  page: 1,
  limit: 10,
  order: '-id',
  q: '',
  status: '',
};

const INIT_STATE: SharedState = {
  // CUSTOMER DASHBOARD
  customerDashboardRouteParams: initialCustomerDashboardRouteParams,
  customerDashboardDIDsList: null,
  customerDashboardDIDsListFetchStatus: FETCH_STATUS.NULL,

  // SELECT BOXES
  selectedCompany: null,
  selectedUserAccess: null,
  selectedLocation: null,
  includeSuborgs: false,
  selectedCountry: null,
  jotFormURL: '',
  jotFormURLFetchStatus: FETCH_STATUS.NULL,

  // OTHER SETTINGS
  domainData: null,
  domainDataFetchStatus: FETCH_STATUS.NULL,
  timezone: null,

  fullOrganizationList: [],
  fullOrganizationListFetchStatus: FETCH_STATUS.NULL,

  fullLocationList: [],
  fullLocationListFetchStatus: FETCH_STATUS.NULL,

  fullATATypeList: [],
  fullATATypeListFetchStatus: FETCH_STATUS.NULL,

  fullATAOrgTemplateList: [],
  fullATAOrgTemplateListFetchStatus: FETCH_STATUS.NULL,

  fullATABaseTemplateList: [],
  fullATABaseTemplateListFetchStatus: FETCH_STATUS.NULL,

  fullServiceTypeList: [],
  fullServiceTypeListFetchStatus: FETCH_STATUS.NULL,

  fullServiceTypeCategoryList: [],
  fullServiceTypeCategoryListFetchStatus: FETCH_STATUS.NULL,

  fullRouterProductNameList: [],
  fullRouterProductNameListFetchStatus: FETCH_STATUS.NULL,

  fullSIMCarrierList: [],
  fullSIMCarrierListFetchStatus: FETCH_STATUS.NULL,

  fullServiceList: [],
  fullServiceListFetchStatus: FETCH_STATUS.NULL,

  fullEquipmentGroupList: [],
  fullEquipmentGroupListFetchStatus: FETCH_STATUS.NULL,

  fullPBXList: [],
  fullPBXListFetchStatus: FETCH_STATUS.NULL,

  fullATAOrganizationTemplateList: [],
  fullATAOrganizationTemplateListFetchStatus: FETCH_STATUS.NULL,

  fullDIDList: [],
  fullDIDListFetchStatus: FETCH_STATUS.NULL,

  fullAsyncTaskTypesList: [],
  fullAsyncTaskTypesListFetchStatus: FETCH_STATUS.NULL,

  fullCountryCategoriesList: [],
  fullCountryCategoriesListFetchStatus: FETCH_STATUS.NULL,

  fullRouterList: [],
  fullRouterListFetchStatus: FETCH_STATUS.NULL,

  fullDialerTypesList: [],
  fullDialerTypesListFetchStatus: FETCH_STATUS.NULL,

  fullEnclosureTypesList: [],
  fullEnclosureTypesListFetchStatus: FETCH_STATUS.NULL,

  fullWarehouseList: [],
  fullWarehouseListFetchStatus: FETCH_STATUS.NULL,

  fullATAACSProfileList: [],
  fullATAACSProfileListFetchStatus: FETCH_STATUS.NULL,

  fullTCBlockList: [],
  fullTCBlockListFetchStatus: FETCH_STATUS.NULL,

  fullLineTypesList: [],
  fullLineTypesListFetchStatus: FETCH_STATUS.NULL,

  fullSimList: [],
  fullSimListFetchStatus: FETCH_STATUS.NULL,

  fullDataPlanTypeList: [],
  fullDataPlanTypeListFetchStatus: FETCH_STATUS.NULL,

  fullVerifiedNumbersList: [],
  fullVerifiedNumbersListFetchStatus: FETCH_STATUS.NULL,

  fullVerifiedEmailsList: [],
  fullVerifiedEmailsListFetchStatus: FETCH_STATUS.NULL,

  fullIssuesTrackerCodes: [],
  fullIssuesTrackerCodesFetchStatus: FETCH_STATUS.NULL,

  fullAuditEntityTypes: [],
  fullAuditEntityTypesFetchStatus: FETCH_STATUS.NULL,

  fullExternalSystemList: [],
  fullExternalSystemListFetchStatus: FETCH_STATUS.NULL,

  fullExternalProviderList: [],
  fullExternalProviderListFetchStatus: FETCH_STATUS.NULL,

  fullBucketsList: [],
  fullBucketsListFetchStatus: FETCH_STATUS.NULL,

  fullProfileList: [],
  fullProfileListFetchStatus: FETCH_STATUS.NULL,

  fullCountriesList: [],
  fullCountriesListFetchStatus: FETCH_STATUS.NULL,

  fullGroupedCountriesList: [],
  fullGroupedCountriesListFetchStatus: FETCH_STATUS.NULL,

  cachedCountriesByOrganizationID: [],
  cachedCountriesByOrganizationIDFetchStatus: FETCH_STATUS.NULL,

  cachedGroupedCountriesByOrganizationID: [],
  cachedGroupedCountriesByOrganizationIDFetchStatus: FETCH_STATUS.NULL,
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState: INIT_STATE,
  reducers: {
    setCustomerDashboardDIDsList: (state, action) => {
      state.customerDashboardDIDsList = action.payload;
    },
    setCustomerDashboardDIDsListFetchStatus: (state, action) => {
      state.customerDashboardDIDsListFetchStatus = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setSelectedUserAccess: (state, action) => {
      state.selectedUserAccess = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setIncludeSuborgs: (state, action) => {
      state.includeSuborgs = action.payload;
    },
    setDomainData: (state, action) => {
      state.domainData = action.payload;
    },
    setDomainDataFetchStatus: (state, action) => {
      state.domainDataFetchStatus = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setJotFormURL: (state, action) => {
      state.jotFormURL = action.payload;
    },
    setJotFormURLFetchStatus: (state, action) => {
      state.jotFormURLFetchStatus = action.payload;
    },

    setFullOrganizationList: (state, action) => {
      state.fullOrganizationList = action.payload;
    },
    setFullOrganizationListFetchStatus: (state, action) => {
      state.fullOrganizationListFetchStatus = action.payload;
    },

    setFullLocationList: (state, action) => {
      state.fullLocationList = action.payload;
    },
    setFullLocationListFetchStatus: (state, action) => {
      state.fullLocationListFetchStatus = action.payload;
    },

    setFullRouterList: (state, action) => {
      state.fullRouterList = action.payload;
    },
    setFullRouterListFetchStatus: (state, action) => {
      state.fullRouterListFetchStatus = action.payload;
    },

    setFullServiceList: (state, action) => {
      state.fullServiceList = action.payload;
    },
    setFullServiceListFetchStatus: (state, action) => {
      state.fullServiceListFetchStatus = action.payload;
    },

    setFullSimList: (state, action) => {
      state.fullSimList = action.payload;
    },
    setFullSimListFetchStatus: (state, action) => {
      state.fullSimListFetchStatus = action.payload;
    },

    setFullDataPlanTypeList: (state, action) => {
      state.fullDataPlanTypeList = action.payload;
    },
    setFullDataPlanTypeListFetchStatus: (state, action) => {
      state.fullDataPlanTypeListFetchStatus = action.payload;
    },

    setFullServiceTypeList: (state, action) => {
      state.fullServiceTypeList = action.payload;
    },
    setFullServiceTypeListFetchStatus: (state, action) => {
      state.fullServiceTypeListFetchStatus = action.payload;
    },

    setFullServiceTypeCategoryList: (state, action) => {
      state.fullServiceTypeCategoryList = action.payload;
    },
    setFullServiceTypeCategoryListFetchStatus: (state, action) => {
      state.fullServiceTypeCategoryListFetchStatus = action.payload;
    },

    setFullEquipmentGroupList: (state, action) => {
      state.fullEquipmentGroupList = action.payload;
    },
    setFullEquipmentGroupListFetchStatus: (state, action) => {
      state.fullEquipmentGroupListFetchStatus = action.payload;
    },

    setFullPBXList: (state, action) => {
      state.fullPBXList = action.payload;
    },
    setFullPBXListFetchStatus: (state, action) => {
      state.fullPBXListFetchStatus = action.payload;
    },

    setFullATATypeList: (state, action) => {
      state.fullATATypeList = action.payload;
    },
    setFullATATypeListFetchStatus: (state, action) => {
      state.fullATATypeListFetchStatus = action.payload;
    },

    setFullDialerTypesList: (state, action) => {
      state.fullDialerTypesList = action.payload;
    },
    setFullDialerTypesListFetchStatus: (state, action) => {
      state.fullDialerTypesListFetchStatus = action.payload;
    },

    setFullATAOrgTemplateList: (state, action) => {
      state.fullATAOrgTemplateList = action.payload;
    },
    setFullATAOrgTemplateListFetchStatus: (state, action) => {
      state.fullATAOrgTemplateListFetchStatus = action.payload;
    },

    setFullATABaseTemplateList: (state, action) => {
      state.fullATABaseTemplateList = action.payload;
    },
    setFullATABaseTemplateListFetchStatus: (state, action) => {
      state.fullATABaseTemplateListFetchStatus = action.payload;
    },

    setFullAsyncTaskTypesList: (state, action) => {
      state.fullAsyncTaskTypesList = action.payload;
    },
    setFullAsyncTaskTypesListFetchStatus: (state, action) => {
      state.fullAsyncTaskTypesListFetchStatus = action.payload;
    },

    setFullBucketsList: (state, action) => {
      state.fullBucketsList = action.payload;
    },
    setFullBucketsListFetchStatus: (state, action) => {
      state.fullBucketsListFetchStatus = action.payload;
    },

    setFullEnclosureTypesList: (state, action) => {
      state.fullEnclosureTypesList = action.payload;
    },
    setFullEnclosureTypesListFetchStatus: (state, action) => {
      state.fullEnclosureTypesListFetchStatus = action.payload;
    },

    setFullCountryCategoriesList: (state, action) => {
      state.fullCountryCategoriesList = action.payload;
    },
    setFullCountryCategoriesListFetchStatus: (state, action) => {
      state.fullCountryCategoriesListFetchStatus = action.payload;
    },

    setFullWarehouseList: (state, action) => {
      state.fullWarehouseList = action.payload;
    },
    setFullWarehouseListFetchStatus: (state, action) => {
      state.fullWarehouseListFetchStatus = action.payload;
    },

    setFullATAACSProfileList: (state, action) => {
      state.fullATAACSProfileList = action.payload;
    },
    setFullATAACSProfileListFetchStatus: (state, action) => {
      state.fullATAACSProfileListFetchStatus = action.payload;
    },

    setFullTCBlockList: (state, action) => {
      state.fullTCBlockList = action.payload;
    },
    setFullTCBlockListFetchStatus: (state, action) => {
      state.fullTCBlockListFetchStatus = action.payload;
    },

    setFullLineTypesList: (state, action) => {
      state.fullLineTypesList = action.payload;
    },
    setFullLineTypesListFetchStatus: (state, action) => {
      state.fullLineTypesListFetchStatus = action.payload;
    },

    setFullVerifiedNumbersList: (state, action) => {
      state.fullVerifiedNumbersList = action.payload;
    },
    setFullVerifiedNumbersListFetchStatus: (state, action) => {
      state.fullVerifiedNumbersListFetchStatus = action.payload;
    },

    setFullVerifiedEmailsList: (state, action) => {
      state.fullVerifiedEmailsList = action.payload;
    },
    setFullVerifiedEmailsListFetchStatus: (state, action) => {
      state.fullVerifiedEmailsListFetchStatus = action.payload;
    },

    setFullIssuesTrackerCodes: (state, action) => {
      state.fullIssuesTrackerCodes = action.payload;
    },
    setFullIssuesTrackerCodesFetchStatus: (state, action) => {
      state.fullIssuesTrackerCodesFetchStatus = action.payload;
    },
    setFullCountriesList: (state, action) => {
      state.fullCountriesList = action.payload;
    },
    setFullCountriesListFetchStatus: (state, action) => {
      state.fullCountriesListFetchStatus = action.payload;
    },
    setFullGroupedCountriesList: (state, action) => {
      state.fullGroupedCountriesList = action.payload;
    },
    setFullGroupedCountriesListFetchStatus: (state, action) => {
      state.fullGroupedCountriesListFetchStatus = action.payload;
    },
    setCachedCountriesByOrganizationID: (state, action) => {
      state.cachedCountriesByOrganizationID = action.payload;
    },
    setCachedCountriesByOrganizationIDFetchStatus: (state, action) => {
      state.cachedCountriesByOrganizationIDFetchStatus = action.payload;
    },
    setCachedGroupedCountriesByOrganizationID: (state, action) => {
      state.cachedGroupedCountriesByOrganizationID = action.payload;
    },
    setCachedGroupedCountriesByOrganizationIDFetchStatus: (state, action) => {
      state.cachedGroupedCountriesByOrganizationIDFetchStatus = action.payload;
    },

    setFullRouterProductNameList: (state, action) => {
      state.fullRouterProductNameList = action.payload;
    },
    setFullRouterProductNameListFetchStatus: (state, action) => {
      state.fullRouterProductNameListFetchStatus = action.payload;
    },

    setFullAuditEntityTypes: (state, action) => {
      state.fullAuditEntityTypes = action.payload;
    },
    setFullAuditEntityTypesFetchStatus: (state, action) => {
      state.fullAuditEntityTypesFetchStatus = action.payload;
    },

    setFullExternalSystemList: (state, action) => {
      state.fullExternalSystemList = action.payload;
    },
    setFullExternalSystemListFetchStatus: (state, action) => {
      state.fullExternalSystemListFetchStatus = action.payload;
    },

    setFullExternalProviderList: (state, action) => {
      state.fullExternalProviderList = action.payload;
    },
    setFullExternalProviderListFetchStatus: (state, action) => {
      state.fullExternalProviderListFetchStatus = action.payload;
    },

    setFullSIMCarrierList: (state, action) => {
      state.fullSIMCarrierList = action.payload;
    },
    setFullSIMCarrierListFetchStatus: (state, action) => {
      state.fullSIMCarrierListFetchStatus = action.payload;
    },

    setFullProfileList: (state, action) => {
      state.fullProfileList = action.payload;
    },
    setFullProfileListFetchStatus: (state, action) => {
      state.fullProfileListFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'dashboard-dids') {
        state.customerDashboardRouteParams = {
          ...state.customerDashboardRouteParams,
          ...payload.data,
          limit: isNaN(payload.data.limit) ? state.customerDashboardRouteParams.limit : payload.data.limit,
        };
      }
    });

    // CUSTOMER DASHBOARD DID LIST
    builder.addCase(getCustomerDashboardDIDsList.pending, state => {
      state.customerDashboardDIDsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getCustomerDashboardDIDsList.rejected, (state, action) => {
      state.customerDashboardDIDsListFetchStatus = FETCH_STATUS.REJECTED;
      state.customerDashboardDIDsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getCustomerDashboardDIDsList.fulfilled, (state, action) => {
      state.customerDashboardDIDsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.customerDashboardDIDsList = action.payload;
    });

    // GET DOMAIN
    builder.addCase(getDomain.pending, state => {
      state.domainDataFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDomain.rejected, (state, action) => {
      state.domainDataFetchStatus = FETCH_STATUS.REJECTED;
      state.domainData = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getDomain.fulfilled, (state, action) => {
      state.domainDataFetchStatus = FETCH_STATUS.FULFILLED;
      state.domainData = action.payload;
    });

    // GET JOT FORM
    builder.addCase(getJotFormURL.pending, state => {
      state.jotFormURLFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getJotFormURL.rejected, (state, action) => {
      state.jotFormURLFetchStatus = FETCH_STATUS.REJECTED;
      state.jotFormURL = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getJotFormURL.fulfilled, (state, action) => {
      state.jotFormURLFetchStatus = FETCH_STATUS.FULFILLED;
      state.jotFormURL = action.payload;
    });

    // GET FULL ORGANIZATIONS LIST
    builder.addCase(getFullOrganizationList.pending, state => {
      state.fullOrganizationListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullOrganizationList.rejected, (state, action) => {
      state.fullOrganizationListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullOrganizationList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullOrganizationList.fulfilled, (state, action) => {
      state.fullOrganizationListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullOrganizationList = action.payload;
    });

    // GET FULL LOCATIONS LIST
    builder.addCase(getFullLocationList.pending, state => {
      state.fullLocationListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullLocationList.rejected, (state, action) => {
      state.fullLocationListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullLocationList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullLocationList.fulfilled, (state, action) => {
      state.fullLocationListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullLocationList = action.payload;
    });

    // GET FULL ROUTERS LIST
    builder.addCase(getFullRouterList.pending, state => {
      state.fullRouterListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullRouterList.rejected, (state, action) => {
      state.fullRouterListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullRouterList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullRouterList.fulfilled, (state, action) => {
      state.fullRouterListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullRouterList = action.payload;
    });

    // GET FULL SERVICES LIST
    builder.addCase(getFullServiceList.pending, state => {
      state.fullServiceListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullServiceList.rejected, (state, action) => {
      state.fullServiceListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullServiceList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullServiceList.fulfilled, (state, action) => {
      state.fullServiceListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullServiceList = action.payload;
    });

    // GET FULL SIMs LIST
    builder.addCase(getFullSimsList.pending, state => {
      state.fullSimListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullSimsList.rejected, (state, action) => {
      state.fullSimListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullSimList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullSimsList.fulfilled, (state, action) => {
      state.fullSimListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullSimList = action.payload;
    });

    // GET FULL DATA PLAN TYPES LIST
    builder.addCase(getFullDataPlanTypeList.pending, state => {
      state.fullDataPlanTypeListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullDataPlanTypeList.rejected, (state, action) => {
      state.fullDataPlanTypeListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullDataPlanTypeList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullDataPlanTypeList.fulfilled, (state, action) => {
      state.fullDataPlanTypeListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullDataPlanTypeList = action.payload;
    });

    // GET FULL SERVICE TYPES LIST
    builder.addCase(getFullServiceTypeList.pending, state => {
      state.fullServiceTypeListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullServiceTypeList.rejected, (state, action) => {
      state.fullServiceTypeListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullServiceTypeList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullServiceTypeList.fulfilled, (state, action) => {
      state.fullServiceTypeListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullServiceTypeList = action.payload;
    });

    // GET FULL SERVICE TYPE CATEGORIES LIST
    builder.addCase(getFullServiceTypeCategoryList.pending, state => {
      state.fullServiceTypeCategoryListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullServiceTypeCategoryList.rejected, (state, action) => {
      state.fullServiceTypeCategoryListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullServiceTypeCategoryList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullServiceTypeCategoryList.fulfilled, (state, action) => {
      state.fullServiceTypeCategoryListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullServiceTypeCategoryList = action.payload;
    });

    // GET FULL EQUIPMENT GROUPS LIST
    builder.addCase(getFullEquipmentGroupList.pending, state => {
      state.fullEquipmentGroupListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullEquipmentGroupList.rejected, (state, action) => {
      state.fullEquipmentGroupListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullEquipmentGroupList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullEquipmentGroupList.fulfilled, (state, action) => {
      state.fullEquipmentGroupListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullEquipmentGroupList = action.payload;
    });

    // GET FULL PBXs LIST
    builder.addCase(getFullPBXList.pending, state => {
      state.fullPBXListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullPBXList.rejected, (state, action) => {
      state.fullPBXListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullPBXList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullPBXList.fulfilled, (state, action) => {
      state.fullPBXListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullPBXList = action.payload;
    });

    // GET FULL ATA TYPES LIST
    builder.addCase(getFullATATypeList.pending, state => {
      state.fullATATypeListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullATATypeList.rejected, (state, action) => {
      state.fullATATypeListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullATATypeList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullATATypeList.fulfilled, (state, action) => {
      state.fullATATypeListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullATATypeList = action.payload;
    });

    // GET FULL DIALER TYPES LIST
    builder.addCase(getFullDialerTypesList.pending, state => {
      state.fullDialerTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullDialerTypesList.rejected, (state, action) => {
      state.fullDialerTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullDialerTypesList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullDialerTypesList.fulfilled, (state, action) => {
      state.fullDialerTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullDialerTypesList = action.payload;
    });

    // GET FULL ATA ORG TEMPLATES LIST
    builder.addCase(getFullATAOrgTemplateList.pending, state => {
      state.fullATAOrgTemplateListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullATAOrgTemplateList.rejected, (state, action) => {
      state.fullATAOrgTemplateListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullATAOrgTemplateList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullATAOrgTemplateList.fulfilled, (state, action) => {
      state.fullATAOrgTemplateListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullATAOrgTemplateList = action.payload;
    });

    // GET FULL ATA BASE TEMPLATES LIST
    builder.addCase(getFullATABaseTemplateList.pending, state => {
      state.fullATABaseTemplateListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullATABaseTemplateList.rejected, (state, action) => {
      state.fullATABaseTemplateListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullATABaseTemplateList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullATABaseTemplateList.fulfilled, (state, action) => {
      state.fullATABaseTemplateListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullATABaseTemplateList = action.payload;
    });

    // GET FULL ASYNC TASK TYPE LIST
    builder.addCase(getFullAsyncTaskTypesList.pending, state => {
      state.fullAsyncTaskTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullAsyncTaskTypesList.rejected, (state, action) => {
      state.fullAsyncTaskTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullAsyncTaskTypesList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullAsyncTaskTypesList.fulfilled, (state, action) => {
      state.fullAsyncTaskTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullAsyncTaskTypesList = action.payload;
    });

    // GET FULL BUCKETS LIST
    builder.addCase(getFullBucketsList.pending, state => {
      state.fullBucketsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullBucketsList.rejected, (state, action) => {
      state.fullBucketsListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullBucketsList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullBucketsList.fulfilled, (state, action) => {
      state.fullBucketsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullBucketsList = action.payload;
    });

    // GET FULL ENCLOSURES LIST
    builder.addCase(getFullEnclosureTypesList.pending, state => {
      state.fullEnclosureTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullEnclosureTypesList.rejected, (state, action) => {
      state.fullEnclosureTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullEnclosureTypesList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullEnclosureTypesList.fulfilled, (state, action) => {
      state.fullEnclosureTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullEnclosureTypesList = action.payload;
    });

    // GET FULL COUNTRY CATEGORIES LIST
    builder.addCase(getFullCountryCategoriesList.pending, state => {
      state.fullCountryCategoriesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullCountryCategoriesList.rejected, (state, action) => {
      state.fullCountryCategoriesListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullCountryCategoriesList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullCountryCategoriesList.fulfilled, (state, action) => {
      state.fullCountryCategoriesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullCountryCategoriesList = action.payload;
    });

    // GET FULL WAREHOUSES LIST
    builder.addCase(getFullWarehouseList.pending, state => {
      state.fullWarehouseListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullWarehouseList.rejected, (state, action) => {
      state.fullWarehouseListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullWarehouseList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullWarehouseList.fulfilled, (state, action) => {
      state.fullWarehouseListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullWarehouseList = action.payload;
    });

    // GET FULL ACS PROFILES LIST
    builder.addCase(getFullATAACSProfileList.pending, state => {
      state.fullATAACSProfileListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullATAACSProfileList.rejected, (state, action) => {
      state.fullATAACSProfileListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullATAACSProfileList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullATAACSProfileList.fulfilled, (state, action) => {
      state.fullATAACSProfileListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullATAACSProfileList = action.payload;
    });

    // GET FULL TC-BLOCKS LIST
    builder.addCase(getFullTCBlockList.pending, state => {
      state.fullTCBlockListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullTCBlockList.rejected, (state, action) => {
      state.fullTCBlockListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullTCBlockList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullTCBlockList.fulfilled, (state, action) => {
      state.fullTCBlockListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullTCBlockList = action.payload;
    });

    // GET FULL LINE TYPES LIST
    builder.addCase(getFullLineTypesList.pending, state => {
      state.fullLineTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullLineTypesList.rejected, (state, action) => {
      state.fullLineTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullLineTypesList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullLineTypesList.fulfilled, (state, action) => {
      state.fullLineTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullLineTypesList = action.payload;
    });

    // GET FULL NUMBERS LIST
    builder.addCase(getFullVerifiedNumbersList.pending, state => {
      state.fullVerifiedNumbersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullVerifiedNumbersList.rejected, (state, action) => {
      state.fullVerifiedNumbersListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullVerifiedNumbersList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullVerifiedNumbersList.fulfilled, (state, action) => {
      state.fullVerifiedNumbersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullVerifiedNumbersList = action.payload;
    });

    // GET FULL EMAILS LIST
    builder.addCase(getFullVerifiedEmailsList.pending, state => {
      state.fullVerifiedEmailsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullVerifiedEmailsList.rejected, (state, action) => {
      state.fullVerifiedEmailsListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullVerifiedEmailsList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullVerifiedEmailsList.fulfilled, (state, action) => {
      state.fullVerifiedEmailsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullVerifiedEmailsList = action.payload;
    });

    // GET FULL ISSUES CODES LIST
    builder.addCase(getFullIssuesTrackerCodesList.pending, state => {
      state.fullIssuesTrackerCodesFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullIssuesTrackerCodesList.rejected, (state, action) => {
      state.fullIssuesTrackerCodesFetchStatus = FETCH_STATUS.REJECTED;
      state.fullIssuesTrackerCodes = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullIssuesTrackerCodesList.fulfilled, (state, action) => {
      state.fullIssuesTrackerCodesFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullIssuesTrackerCodes = action.payload;
    });

    // GET FULL COUNTRIES LIST
    builder.addCase(getFullCountriesList.pending, state => {
      state.fullCountriesListFetchStatus = FETCH_STATUS.PENDING;
      state.fullGroupedCountriesListFetchStatus = FETCH_STATUS.PENDING;
      state.cachedCountriesByOrganizationIDFetchStatus = FETCH_STATUS.PENDING;
      state.cachedGroupedCountriesByOrganizationIDFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullCountriesList.rejected, (state, action) => {
      state.fullCountriesListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullGroupedCountriesListFetchStatus = FETCH_STATUS.REJECTED;
      state.cachedCountriesByOrganizationIDFetchStatus = FETCH_STATUS.REJECTED;
      state.cachedGroupedCountriesByOrganizationIDFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullCountriesList.fulfilled, state => {
      state.fullCountriesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullGroupedCountriesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.cachedCountriesByOrganizationIDFetchStatus = FETCH_STATUS.FULFILLED;
      state.cachedGroupedCountriesByOrganizationIDFetchStatus = FETCH_STATUS.FULFILLED;
    });

    // GET FULL EMAILS LIST
    builder.addCase(getRouterProductNameTypesList.pending, state => {
      state.fullRouterProductNameListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRouterProductNameTypesList.rejected, (state, action) => {
      state.fullRouterProductNameListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullRouterProductNameList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRouterProductNameTypesList.fulfilled, (state, action) => {
      state.fullRouterProductNameListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullRouterProductNameList = action.payload;
    });

    // GET FULL ENTITY TYPES LIST
    builder.addCase(getFullAuditEntityTypes.pending, state => {
      state.fullAuditEntityTypesFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullAuditEntityTypes.rejected, (state, action) => {
      state.fullAuditEntityTypesFetchStatus = FETCH_STATUS.REJECTED;
      state.fullAuditEntityTypes = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullAuditEntityTypes.fulfilled, (state, action) => {
      state.fullAuditEntityTypesFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullAuditEntityTypes = action.payload;
    });

    // GET FULL EXTERNAL SYSTEMS LIST
    builder.addCase(getFullExternalSystemList.pending, state => {
      state.fullExternalSystemListFetchStatus = FETCH_STATUS.PENDING;
      state.fullExternalProviderListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullExternalSystemList.rejected, (state, action) => {
      state.fullExternalSystemListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullExternalProviderListFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullExternalSystemList.fulfilled, (state, action) => {
      state.fullExternalSystemListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullExternalProviderListFetchStatus = FETCH_STATUS.FULFILLED;
    });

    // GET FULL CARRIERS LIST
    builder.addCase(getSIMCarrierList.pending, state => {
      state.fullSIMCarrierListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSIMCarrierList.rejected, (state, action) => {
      state.fullSIMCarrierListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullSIMCarrierList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getSIMCarrierList.fulfilled, (state, action) => {
      state.fullSIMCarrierListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullSIMCarrierList = action.payload;
    });

    // GET FULL PROFILES LIST
    builder.addCase(getFullProfileList.pending, state => {
      state.fullProfileListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullProfileList.rejected, (state, action) => {
      state.fullProfileListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullProfileList = '';
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getFullProfileList.fulfilled, (state, action) => {
      state.fullProfileListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullProfileList = action.payload;
    });
  },
});

export const {
  setCustomerDashboardDIDsList,
  setCustomerDashboardDIDsListFetchStatus,
  setDomainDataFetchStatus,
  setDomainData,
  setIncludeSuborgs,
  setSelectedLocation,
  setSelectedCompany,
  setTimezone,
  setSelectedUserAccess,
  setSelectedCountry,
  setJotFormURLFetchStatus,
  setJotFormURL,
  setFullOrganizationListFetchStatus,
  setFullOrganizationList,
  setFullLocationListFetchStatus,
  setFullLocationList,
  setFullRouterList,
  setFullRouterListFetchStatus,
  setFullServiceList,
  setFullServiceListFetchStatus,
  setFullSimList,
  setFullSimListFetchStatus,
  setFullServiceTypeList,
  setFullServiceTypeListFetchStatus,
  setFullDataPlanTypeList,
  setFullDataPlanTypeListFetchStatus,
  setFullServiceTypeCategoryList,
  setFullServiceTypeCategoryListFetchStatus,
  setFullEquipmentGroupList,
  setFullEquipmentGroupListFetchStatus,
  setFullPBXList,
  setFullPBXListFetchStatus,
  setFullATATypeList,
  setFullATATypeListFetchStatus,
  setFullDialerTypesList,
  setFullDialerTypesListFetchStatus,
  setFullATAOrgTemplateList,
  setFullATAOrgTemplateListFetchStatus,
  setFullATABaseTemplateList,
  setFullATABaseTemplateListFetchStatus,
  setFullAsyncTaskTypesList,
  setFullAsyncTaskTypesListFetchStatus,
  setFullBucketsList,
  setFullBucketsListFetchStatus,
  setFullEnclosureTypesListFetchStatus,
  setFullEnclosureTypesList,
  setFullCountryCategoriesList,
  setFullCountryCategoriesListFetchStatus,
  setFullWarehouseListFetchStatus,
  setFullWarehouseList,
  setFullATAACSProfileList,
  setFullATAACSProfileListFetchStatus,
  setFullTCBlockList,
  setFullTCBlockListFetchStatus,
  setFullLineTypesList,
  setFullLineTypesListFetchStatus,
  setFullVerifiedNumbersList,
  setFullVerifiedNumbersListFetchStatus,
  setFullVerifiedEmailsList,
  setFullVerifiedEmailsListFetchStatus,
  setFullIssuesTrackerCodesFetchStatus,
  setFullIssuesTrackerCodes,
  setCachedCountriesByOrganizationIDFetchStatus,
  setCachedGroupedCountriesByOrganizationID,
  setCachedGroupedCountriesByOrganizationIDFetchStatus,
  setCachedCountriesByOrganizationID,
  setFullCountriesListFetchStatus,
  setFullGroupedCountriesList,
  setFullGroupedCountriesListFetchStatus,
  setFullCountriesList,
  setFullRouterProductNameList,
  setFullRouterProductNameListFetchStatus,
  setFullAuditEntityTypesFetchStatus,
  setFullAuditEntityTypes,
  setFullExternalSystemList,
  setFullExternalSystemListFetchStatus,
  setFullExternalProviderListFetchStatus,
  setFullExternalProviderList,
  setFullSIMCarrierList,
  setFullSIMCarrierListFetchStatus,
  setFullProfileListFetchStatus,
  setFullProfileList,
} = sharedSlice.actions;

export default sharedSlice.reducer;
