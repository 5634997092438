import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { PortRequestType } from '../../types/port-request/port-request.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  cancelPortingDetails,
  deletePortingDetails,
  getPortingDetails,
  getPortingsList,
  patchPortingDetails,
  postPortingDetails,
} from './porting.action';

export interface PortingsState {
  portingsList: null | PaginationType<PortRequestType>;
  portingsListFetchStatus: FETCH_STATUS;
  portingsRouteParams: RouteParamsType;
  portingDetails: null | PortRequestType;
  portingDetailsFetchStatus: FETCH_STATUS;
  portingDetailsPostStatus: FETCH_STATUS;
  portingDetailsPatchStatus: FETCH_STATUS;
  portingDetailsDeleteStatus: FETCH_STATUS;
  cancelPortingDeleteStatus: FETCH_STATUS;
}

export const initialPortingsRouteParams = {
  page: 1,
  limit: 10,
  order: '-port_request.id',
  q: '',
};

const INIT_STATE: PortingsState = {
  portingsRouteParams: initialPortingsRouteParams,
  portingsList: null,
  portingsListFetchStatus: FETCH_STATUS.NULL,
  portingDetails: null,
  portingDetailsFetchStatus: FETCH_STATUS.NULL,
  portingDetailsPostStatus: FETCH_STATUS.NULL,
  portingDetailsPatchStatus: FETCH_STATUS.NULL,
  portingDetailsDeleteStatus: FETCH_STATUS.NULL,
  cancelPortingDeleteStatus: FETCH_STATUS.NULL,
};

const portingsSlice = createSlice({
  name: 'portings',
  initialState: INIT_STATE,
  reducers: {
    setPortingsList: (state, action) => {
      state.portingsList = action.payload;
    },
    setPortingsListFetchStatus: (state, action) => {
      state.portingsListFetchStatus = action.payload;
    },
    setPortingDetails: (state, action) => {
      state.portingDetails = action.payload;
    },
    setPortingDetailsFetchStatus: (state, action) => {
      state.portingDetailsFetchStatus = action.payload;
    },
    setPortingDetailsPostStatus: (state, action) => {
      state.portingDetailsPostStatus = action.payload;
    },
    setPortingDetailsPatchStatus: (state, action) => {
      state.portingDetailsPatchStatus = action.payload;
    },
    setPortingDetailsDeleteStatus: (state, action) => {
      state.portingDetailsDeleteStatus = action.payload;
    },
    setCancelPortingDeleteStatus: (state, action) => {
      state.cancelPortingDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'porting') {
        state.portingsRouteParams = { ...state.portingsRouteParams, ...payload.data };
      }
    });

    // CREATE PORTINGS DETAILS
    builder.addCase(postPortingDetails.pending, state => {
      state.portingDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPortingDetails.rejected, (state, action) => {
      state.portingDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postPortingDetails.fulfilled, state => {
      state.portingDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Port request created successfully!');
      });
    });

    // GET PORTINGS LIST
    builder.addCase(getPortingsList.pending, state => {
      state.portingsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPortingsList.rejected, (state, action) => {
      state.portingsListFetchStatus = FETCH_STATUS.REJECTED;
      state.portingsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getPortingsList.fulfilled, (state, action) => {
      state.portingsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.portingsList = action.payload;
    });

    // GET PORTINGS DETAILS
    builder.addCase(getPortingDetails.pending, state => {
      state.portingDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPortingDetails.rejected, (state, action) => {
      state.portingDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.portingDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getPortingDetails.fulfilled, (state, action) => {
      state.portingDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.portingDetails = action.payload;
    });

    // PATCH PORTINGS DETAILS
    builder.addCase(patchPortingDetails.pending, state => {
      state.portingDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchPortingDetails.rejected, (state, action) => {
      state.portingDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchPortingDetails.fulfilled, state => {
      state.portingDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Port request updated successfully!');
      });
    });

    // DELETE PORTINGS DETAILS
    builder.addCase(deletePortingDetails.pending, state => {
      state.portingDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deletePortingDetails.rejected, (state, action) => {
      state.portingDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deletePortingDetails.fulfilled, state => {
      state.portingDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Port request deleted successfully!');
      });
    });

    // CANCEL PORTINGS DETAILS
    builder.addCase(cancelPortingDetails.pending, state => {
      state.cancelPortingDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(cancelPortingDetails.rejected, (state, action) => {
      state.cancelPortingDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(cancelPortingDetails.fulfilled, state => {
      state.cancelPortingDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Port request has been canceled!');
      });
    });
  },
});

export const {
  setPortingDetailsDeleteStatus,
  setPortingsListFetchStatus,
  setPortingDetailsFetchStatus,
  setPortingDetailsPatchStatus,
  setPortingDetailsPostStatus,
  setPortingsList,
  setPortingDetails,
  setCancelPortingDeleteStatus,
} = portingsSlice.actions;

export default portingsSlice.reducer;
