import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import {
  CreateServiceCallTechnicianDto,
  ServiceCallTechnicianType,
  UpdateServiceCallTechnicianDto,
} from '../../types/service-call-technician/service-call-technician.type';
import {
  CreateServiceCallProviderDto,
  ServiceCallProviderType,
  UpdateServiceCallProviderDto,
} from '../../types/service-call-provider/service-call-provider.type';
import { ServiceCallNoteType } from '../../types/service-call-note/service-call-note.type';
import {
  CreateServiceCallRequestDto,
  ServiceCallRequestType,
  UpdateServiceCallRequestDto,
} from '../../types/service-call-request/service-call-request.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';

export const postServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { data: CreateServiceCallTechnicianDto; img?: File },
  { state: RootState }
>('serviceCall/postServiceCallTechnician', async ({ data, img }, thunkAPI) => {
  try {
    const formData = new FormData();
    if (img) formData.append('img', img);
    formData.append('body', JSON.stringify(data));

    const response = await axios.post<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating technician!');
  }
});

export const postServiceCallRequest = createAsyncThunk<
  ServiceCallRequestType,
  { data: CreateServiceCallRequestDto },
  { state: RootState }
>('serviceCall/postServiceCallRequest', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests`,
      data
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating request!');
  }
});

export const postServiceCallNote = createAsyncThunk<
  ServiceCallNoteType,
  { data: { note: string; provider: { id: number } }; fetchDetails: boolean },
  { state: RootState }
>('serviceCall/postServiceCallNote', async ({ data, fetchDetails }, thunkAPI) => {
  try {
    const response = await axios.post<ServiceCallNoteType>(`${process.env.REACT_APP_API_URL}/service-call-notes`, data);

    if (fetchDetails) {
      thunkAPI.dispatch(
        getServiceCallProviderDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallProviderDetails.id })
      );
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating note!');
  }
});

export const postServiceCallProvider = createAsyncThunk<
  ServiceCallProviderType,
  { data: CreateServiceCallProviderDto & { note?: string | null }; attachments: Array<File> },
  { state: RootState }
>('serviceCall/postServiceCallProvider', async ({ data, attachments }, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append('body', JSON.stringify(data));
    if (attachments.length > 0) {
      attachments.forEach(file => {
        formData.append('files', file);
      });
    }

    const response = await axios.post<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating provider!');
  }
});

export const getServiceCallTechniciansList = createAsyncThunk<
  PaginationType<ServiceCallTechnicianType>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallTechniciansList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<ServiceCallTechnicianType>>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching technicians!');
  }
});

export const getServiceCallProvidersList = createAsyncThunk<
  PaginationType<ServiceCallProviderType>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallProvidersList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<ServiceCallProviderType>>(
      `${process.env.REACT_APP_API_URL}/service-call-providers${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching providers!');
  }
});

export const getServiceCallRequestsList = createAsyncThunk<
  PaginationType<ServiceCallRequestType>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallRequestsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };

    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<ServiceCallRequestType>>(
      `${process.env.REACT_APP_API_URL}/service-call-requests${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching requests!');
  }
});

export const getServiceCallTechnicianDetails = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallTechnicianDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching technician!');
  }
});

export const getServiceCallProviderDetails = createAsyncThunk<
  ServiceCallProviderType,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallProviderDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching provider!');
  }
});

export const getServiceCallRequestDetails = createAsyncThunk<
  ServiceCallRequestType,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallRequestDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching request!');
  }
});

export const patchServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number; data: UpdateServiceCallTechnicianDto; img?: File },
  { state: RootState }
>('serviceCall/patchServiceCallTechnician', async ({ data, id, img }, thunkAPI) => {
  try {
    const formData = new FormData();
    if (img) formData.append('img', img);
    formData.append('body', JSON.stringify(data));

    const response = await axios.patch<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating technician!');
  }
});

export const patchServiceCallRequest = createAsyncThunk<
  ServiceCallRequestType,
  { id: number; data: UpdateServiceCallRequestDto },
  { state: RootState }
>('serviceCall/patchServiceCallRequest', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests/${id}`,
      data
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating request!');
  }
});

export const patchServiceCallProvider = createAsyncThunk<
  ServiceCallProviderType,
  {
    id: number;
    data: UpdateServiceCallProviderDto & {
      attachments?: Array<{
        url: string;
        key: string;
        type: string;
        name: string;
      }>;
    };
    attachments: Array<File>;
  },
  { state: RootState }
>('serviceCall/patchServiceCallProvider', async ({ data, attachments, id }, thunkAPI) => {
  const formData = new FormData();
  formData.append('body', JSON.stringify(data));
  if (attachments.length > 0) {
    attachments.forEach(file => {
      formData.append('files', file);
    });
  }

  try {
    const response = await axios.patch<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers/${id}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating provider!');
  }
});

export const deleteServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallTechnician', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(
        getServiceCallTechniciansList(thunkAPI.getState().serviceCallReducer.serviceCallTechniciansRouteParams)
      );
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting technician!');
  }
});

export const deleteServiceCallProvider = createAsyncThunk<
  ServiceCallProviderType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallProvider', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(
        getServiceCallProvidersList(thunkAPI.getState().serviceCallReducer.serviceCallProvidersRouteParams)
      );
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting provider!');
  }
});

export const deleteServiceCallRequest = createAsyncThunk<
  ServiceCallRequestType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallRequest', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(
        getServiceCallRequestsList(thunkAPI.getState().serviceCallReducer.serviceCallProvidersRouteParams)
      );
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting request!');
  }
});

export const deleteServiceCallNote = createAsyncThunk<
  ServiceCallNoteType,
  { id: number; fetchDetails: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallNote', async ({ id, fetchDetails }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallNoteType>(
      `${process.env.REACT_APP_API_URL}/service-call-notes/${id}`
    );
    if (fetchDetails) {
      thunkAPI.dispatch(
        getServiceCallProviderDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallProviderDetails.id })
      );
    }
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting note!');
  }
});
