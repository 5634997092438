import { createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { Contact } from '../../types/contacts/contacts.type';
import {
  createContact,
  updateContact,
  deleteContact,
  getContact,
  getLocationContacts,
  getOrganizationContacts,
} from './contacts.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface ContactsState {
  contactsList: PaginationType<Contact> | null[];
  contactsListFetchStatus: FETCH_STATUS;
  contactsRouteParams: RouteParamsType;
  contactDetails: null | Contact;
  contactDetailsFetchStatus: FETCH_STATUS;
  contactDetailsPostStatus: FETCH_STATUS;
  contactDetailsPatchStatus: FETCH_STATUS;
  contactDetailsDeleteStatus: FETCH_STATUS;
  organizationContacts: PaginationType<Contact> | null;
  organizationContactsFetchStatus: FETCH_STATUS;
}

export const initialContactsRouteParams = {
  page: 1,
  limit: 10,
  order: '-contacts.id',
  q: '',
};

const INIT_STATE: ContactsState = {
  contactsRouteParams: initialContactsRouteParams,
  contactsList: [],
  contactsListFetchStatus: FETCH_STATUS.NULL,
  contactDetails: null,
  contactDetailsFetchStatus: FETCH_STATUS.NULL,
  contactDetailsPostStatus: FETCH_STATUS.NULL,
  contactDetailsPatchStatus: FETCH_STATUS.NULL,
  contactDetailsDeleteStatus: FETCH_STATUS.NULL,
  organizationContacts: null,
  organizationContactsFetchStatus: FETCH_STATUS.NULL,
};

const contactsReducer = createSlice({
  name: 'contacts',
  initialState: INIT_STATE,
  reducers: {
    setContactsList: (state, action) => {
      state.contactsList = action.payload;
    },
    setContactsListFetchStatus: (state, action) => {
      state.contactsListFetchStatus = action.payload;
    },
    setContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    setContactDetailsFetchStatus: (state, action) => {
      state.contactDetailsFetchStatus = action.payload;
    },
    setContactDetailsPostStatus: (state, action) => {
      state.contactDetailsPostStatus = action.payload;
    },
    setContactDetailsPatchStatus: (state, action) => {
      state.contactDetailsPatchStatus = action.payload;
    },
    setContactDetailsDeleteStatus: (state, action) => {
      state.contactDetailsDeleteStatus = action.payload;
    },
    setOrganizationContacts: (state, action) => {
      state.organizationContacts = action.payload;
    },
    setOrganizationContactsFetchStatus: (state, action) => {
      state.organizationContactsFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    // Create Contact
    builder.addCase(createContact.pending, state => {
      state.contactDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(createContact.rejected, (state, action) => {
      state.contactDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(createContact.fulfilled, state => {
      state.contactDetailsPostStatus = FETCH_STATUS.FULFILLED;
    });

    // Fetch Contact by ID
    builder.addCase(getContact.pending, state => {
      state.contactDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getContact.rejected, (state, action) => {
      state.contactDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.contactDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.contactDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.contactDetails = action.payload;
    });

    // Update Contact
    builder.addCase(updateContact.pending, state => {
      state.contactDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(updateContact.rejected, (state, action) => {
      state.contactDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(updateContact.fulfilled, state => {
      state.contactDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Contact updated successfully!');
      });
    });

    // Delete Contact
    builder.addCase(deleteContact.pending, state => {
      state.contactDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.contactDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteContact.fulfilled, state => {
      state.contactDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Contact deleted successfully!');
      });
    });

    // Fetch Location Contacts
    builder.addCase(getLocationContacts.pending, state => {
      state.contactsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationContacts.rejected, (state, action) => {
      state.contactsListFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(typeof action.payload === 'string' ? action.payload : 'Error fetching location contacts!');
      });
    });

    builder.addCase(getLocationContacts.fulfilled, (state, action) => {
      state.contactsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.contactsList = action.payload;
    });

    builder.addCase(getOrganizationContacts.pending, state => {
      state.organizationContactsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getOrganizationContacts.rejected, (state, action) => {
      state.organizationContactsFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getOrganizationContacts.fulfilled, (state, action) => {
      state.organizationContactsFetchStatus = FETCH_STATUS.FULFILLED;
      state.organizationContacts = action.payload;
    });
  },
});

export const {
  setContactsList,
  setContactsListFetchStatus,
  setContactDetails,
  setContactDetailsFetchStatus,
  setContactDetailsPostStatus,
  setContactDetailsPatchStatus,
  setContactDetailsDeleteStatus,
  setOrganizationContacts,
  setOrganizationContactsFetchStatus,
} = contactsReducer.actions;

export default contactsReducer.reducer;
