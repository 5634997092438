import { PermissionArrayType } from '../../../types/other';
import * as yup from 'yup';
import { CreateServiceCallAreaDto } from '../../../types/service-call-area/service-call-area.type';
import { CreateAddressDto } from '../../../types/address/address.type';
import { PERMISSIONROLECONSTANTS } from '../../../features/utils/utils.action';

export const serviceCallProviderPermissions: PermissionArrayType = {
  CREATE: [PERMISSIONROLECONSTANTS.superadmin],
  READ: [PERMISSIONROLECONSTANTS.superadmin],
  UPDATE: [PERMISSIONROLECONSTANTS.superadmin],
  DELETE: [PERMISSIONROLECONSTANTS.superadmin],
};

export const serviceCallProviderValidationSchema = yup.object({
  name: yup.string().required('Required'),
  website: yup.string().matches(RegExp('^$|(https?|chrome):\\/\\/[^\\s$.?#].[^\\s]*$'), 'Invalid URL').nullable(true),
  isActive: yup.boolean(),
  address: yup
    .object({
      zip_code: yup.string().required('Required'),
    })
    .nullable(true),
});

export interface ServiceCallProviderForm {
  id: number | null;
  name: string;
  website: string | null;
  isActive: boolean;
  areas: Array<CreateServiceCallAreaDto>;
  address: CreateAddressDto | null;
  note?: string | null;
  attachments: Array<{
    url: string;
    key: string;
    type: string;
    name: string;
  }>;
  files: Array<File>;
  contacts: Array<{
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    mobileCountryCode: string;
    role: string;
    isMainContact: boolean;
    id?: number;
  }>;
}
