import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { TcSimType } from '../../types/tc-sim/tc-sim.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteTcSimDetails,
  getTcSimDetails,
  getTcSimsList,
  patchTcSimDetails,
  postTcSimDetails,
  postTcSims,
} from './tcSims.action';

export interface TcSimsState {
  tcSimsList: null | PaginationType<TcSimType>;
  tcSimsListFetchStatus: FETCH_STATUS;
  tcSimsRouteParams: RouteParamsType;
  tcSimDetails: null | TcSimType;
  tcSimDetailsFetchStatus: FETCH_STATUS;
  tcSimDetailsPostStatus: FETCH_STATUS;
  tcSimDetailsPatchStatus: FETCH_STATUS;
  tcSimDetailsDeleteStatus: FETCH_STATUS;
  tcSimsPostStatus: FETCH_STATUS;
  tcSimsBulk: null | Array<unknown>;
}

export const initialTcSimsRouteParams = {
  page: 1,
  limit: 10,
  order: '-tc_sim.id',
  warehouse_id: '',
  q: '',
  in_service: 'all',
  carrier: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE: TcSimsState = {
  tcSimsRouteParams: initialTcSimsRouteParams,
  tcSimsList: null,
  tcSimsListFetchStatus: FETCH_STATUS.NULL,
  tcSimDetails: null,
  tcSimDetailsFetchStatus: FETCH_STATUS.NULL,
  tcSimDetailsPostStatus: FETCH_STATUS.NULL,
  tcSimDetailsPatchStatus: FETCH_STATUS.NULL,
  tcSimDetailsDeleteStatus: FETCH_STATUS.NULL,
  tcSimsPostStatus: FETCH_STATUS.NULL,
  tcSimsBulk: null,
};

const tcSimsSlice = createSlice({
  name: 'tcSims',
  initialState: INIT_STATE,
  reducers: {
    setTcSimsList: (state, action) => {
      state.tcSimsList = action.payload;
    },
    setTcSimsListFetchStatus: (state, action) => {
      state.tcSimsListFetchStatus = action.payload;
    },
    setTcSimDetails: (state, action) => {
      state.tcSimDetails = action.payload;
    },
    setTcSimDetailsFetchStatus: (state, action) => {
      state.tcSimDetailsFetchStatus = action.payload;
    },
    setTcSimDetailsPostStatus: (state, action) => {
      state.tcSimDetailsPostStatus = action.payload;
    },
    setTcSimDetailsPatchStatus: (state, action) => {
      state.tcSimDetailsPatchStatus = action.payload;
    },
    setTcSimDetailsDeleteStatus: (state, action) => {
      state.tcSimDetailsDeleteStatus = action.payload;
    },
    setTcSimsPostStatus: (state, action) => {
      state.tcSimsPostStatus = action.payload;
    },
    setTcSimsBulk: (state, action) => {
      state.tcSimsBulk = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'sims') {
        state.tcSimsRouteParams = { ...state.tcSimsRouteParams, ...payload.data };
      }
    });

    // CREATE TC-SIMs DETAILS
    builder.addCase(postTcSims.pending, state => {
      state.tcSimsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postTcSims.rejected, (state, action) => {
      state.tcSimsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postTcSims.fulfilled, state => {
      state.tcSimsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('SIMs created successfully!');
      });
    });

    // CREATE TC-SIMs DETAILS
    builder.addCase(postTcSimDetails.pending, state => {
      state.tcSimDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postTcSimDetails.rejected, (state, action) => {
      state.tcSimDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postTcSimDetails.fulfilled, state => {
      state.tcSimDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('SIM created successfully!');
      });
    });

    // GET TC-SIMs LIST
    builder.addCase(getTcSimsList.pending, state => {
      state.tcSimsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTcSimsList.rejected, (state, action) => {
      state.tcSimsListFetchStatus = FETCH_STATUS.REJECTED;
      state.tcSimsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getTcSimsList.fulfilled, (state, action) => {
      state.tcSimsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.tcSimsList = action.payload;
    });

    // GET TC-SIMs DETAILS
    builder.addCase(getTcSimDetails.pending, state => {
      state.tcSimDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTcSimDetails.rejected, (state, action) => {
      state.tcSimDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.tcSimDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getTcSimDetails.fulfilled, (state, action) => {
      state.tcSimDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.tcSimDetails = action.payload;
    });

    // PATCH TC-SIMs DETAILS
    builder.addCase(patchTcSimDetails.pending, state => {
      state.tcSimDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchTcSimDetails.rejected, (state, action) => {
      state.tcSimDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchTcSimDetails.fulfilled, state => {
      state.tcSimDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('SIM updated successfully!');
      });
    });

    // DELETE TC-SIMs DETAILS
    builder.addCase(deleteTcSimDetails.pending, state => {
      state.tcSimDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteTcSimDetails.rejected, (state, action) => {
      state.tcSimDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteTcSimDetails.fulfilled, state => {
      state.tcSimDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('SIM deleted successfully!');
      });
    });
  },
});

export const {
  setTcSimDetailsDeleteStatus,
  setTcSimsListFetchStatus,
  setTcSimDetailsFetchStatus,
  setTcSimDetailsPatchStatus,
  setTcSimDetailsPostStatus,
  setTcSimsList,
  setTcSimDetails,
  setTcSimsPostStatus,
  setTcSimsBulk,
} = tcSimsSlice.actions;

export default tcSimsSlice.reducer;
