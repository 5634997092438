export enum REQUEST_TYPE {
  INSTALLATION = 1,
  SITE_SURVEY = 2,
  BREAK_FIX = 3,
  CUSTOM = 4,
}

export const requestTypeOptions = [
  { id: REQUEST_TYPE.INSTALLATION, name: 'Installation' },
  { id: REQUEST_TYPE.SITE_SURVEY, name: 'Site survey' },
  { id: REQUEST_TYPE.BREAK_FIX, name: 'Break-Fix' },
  { id: REQUEST_TYPE.CUSTOM, name: 'Custom' },
];
