import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import {
  CreateRouterConnectionDto,
  RouterConnectionType,
  RouterIntegrationType,
} from '../../types/router-connection/router-connection.type';
import { setShowModal, setModalContent } from '../../features/utils/utils.slice';
import { PaginationType } from '../../types/utils/pagination.type';
import { SyncRouterType } from '../../types/router/sync-router/sync-router.type';
import { getRouterSyncAsyncTaskList, getRouterUpdateAsyncTaskList } from '../logs/logs.action';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import {
  CreateMicroserviceRouterDto,
  InterfaceRouterStatus,
  MicroserviceRouterType,
  UpdateMicroserviceRouterDto,
} from '../../types/router/router.type';
import { AsyncTaskType } from '../../types/async-task/async-task.type';

export const postRouterConnection = createAsyncThunk<
  RouterConnectionType,
  { data: CreateRouterConnectionDto },
  { state: RootState }
>('routers/postRouterConnection', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<RouterConnectionType>(
      `${process.env.REACT_APP_API_URL}/routers/router-connections`,
      data
    );

    thunkAPI.dispatch(getRouterConnectionList({ organizationId: data.organizationId }));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating connection!');
  }
});

export const getRouterConnectionList = createAsyncThunk<
  Array<RouterConnectionType>,
  { organizationId: number },
  { state: RootState }
>('routers/getRouterConnectionList', async (data, thunkAPI) => {
  try {
    const response = await axios.get<{ items: Array<RouterConnectionType> }>(
      `${process.env.REACT_APP_API_URL}/routers/router-connections?organizationId=${data.organizationId}`
    );
    return response.data?.items || [];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching connections!');
  }
});

export const deleteRouterConnection = createAsyncThunk<
  { success: boolean },
  { organizationId: number; id: number },
  { state: RootState }
>('routers/deleteRouterConnection', async (data, thunkAPI) => {
  try {
    const response = await axios.delete<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/routers/router-connections/${data.id}`
    );

    thunkAPI.dispatch(getRouterConnectionList({ organizationId: data.organizationId }));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting organization connection!');
  }
});

export const getRouterIntegrationsList = createAsyncThunk<Array<RouterIntegrationType>, void, { state: RootState }>(
  'routers/getRouterIntegrationsList',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<{ items: Array<RouterIntegrationType> }>(
        `${process.env.REACT_APP_API_URL}/routers/router-integrations`
      );
      return response.data?.items || [];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching integrations!');
    }
  }
);

export const postSyncExternalRouters = createAsyncThunk<
  { success: boolean },
  { connectionId: number; imeis?: Array<string> },
  { state: RootState }
>('routers/postSyncExternalRouters', async (data, thunkAPI) => {
  try {
    const response = await axios.post<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/routers/router-sync/sync-external-routers`,
      data
    );

    thunkAPI.dispatch(
      getRouterSyncAsyncTaskList({
        type: 'Connection',
        connectionID: data.connectionId,
      })
    );

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting external routers list!');
  }
});

export const postSyncRouters = createAsyncThunk<
  { success: boolean },
  {
    add: Array<number>;
    remove: Array<number>;
    connectionId: number;
    syncedEquipmentGroupId?: number;
    syncedWarehouseId?: number;
  },
  { state: RootState }
>('routers/postSyncRouters', async (data, thunkAPI) => {
  try {
    const response = await axios.post<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/routers/router-sync/sync-routers`,
      data
    );

    thunkAPI.dispatch(getSyncRouters(thunkAPI.getState().routersReducer.syncRouterListParams));
    thunkAPI.dispatch(getSyncRouters(thunkAPI.getState().routersReducer.noSyncRouterListParams));
    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(setShowModal(false));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during syncing routers list!');
  }
});

export const patchSyncRouters = createAsyncThunk<{ success: boolean }, { connectionId: number }, { state: RootState }>(
  'routers/patchSyncRouters',
  async (data, thunkAPI) => {
    try {
      const response = await axios.patch<{ success: boolean }>(
        `${process.env.REACT_APP_API_URL}/routers/router-sync/sync-routers`,
        data
      );

      thunkAPI.dispatch(getRouterUpdateAsyncTaskList({ type: 'Connection', connectionID: data.connectionId }));

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating our routers list!');
    }
  }
);

export const getSyncRouters = createAsyncThunk<
  PaginationType<SyncRouterType>,
  { connectionId: number; limit: number; page: number; q: string; isSynced: boolean },
  { state: RootState }
>('routers/getSyncRouters', async (data, thunkAPI) => {
  try {
    const response = await axios.get<PaginationType<SyncRouterType>>(
      `${process.env.REACT_APP_API_URL}/routers/router-sync?connectionId=${data.connectionId}&limit=${data.limit}&page=${data.page}&q=${data.q}&isSynced=${data.isSynced}`
    );
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting routers list!');
  }
});

export const deleteSyncRouter = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'routers/deleteSyncRouter',
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete<{ success: boolean }>(
        `${process.env.REACT_APP_API_URL}/routers/router-sync/${data.id}`
      );

      thunkAPI.dispatch(getSyncRouters(thunkAPI.getState().routersReducer.syncRouterListParams));
      thunkAPI.dispatch(getSyncRouters(thunkAPI.getState().routersReducer.noSyncRouterListParams));

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting sync router!');
    }
  }
);

export const getRoutersList = createAsyncThunk<
  PaginationType<MicroserviceRouterType>,
  RouteParamsType,
  { state: RootState }
>('routers/getRoutersList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<MicroserviceRouterType>>(
      `${process.env.REACT_APP_API_URL}/routers${queryString ? `?${queryString}` : ''}`
    );
    return response.data || [];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching routers!');
  }
});

export const postRouterDetails = createAsyncThunk<
  MicroserviceRouterType,
  { data: CreateMicroserviceRouterDto },
  { state: RootState }
>('routers/postRouterDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<MicroserviceRouterType>(`${process.env.REACT_APP_API_URL}/routers/`, data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating router!');
  }
});

export const postRoutersDetails = createAsyncThunk<
  AsyncTaskType,
  {
    data: {
      warehouse: { id: number };
      equipment_group: { id: number };
      pending_type: string;
      routers: Array<
        Pick<MicroserviceRouterType, 'serial_number' | 'imei' | 'product_name' | 'pending_type'> & {
          warehouse_location?: { rack: string; shelf: string };
          is_inventory: boolean;
        }
      >;
    };
  },
  { state: RootState }
>('routers/postRoutersDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/routers/bulk`, data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating routers!');
  }
});

export const getRouterDetails = createAsyncThunk<MicroserviceRouterType, { id: number }, { state: RootState }>(
  'routers/getRouterDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<MicroserviceRouterType>(`${process.env.REACT_APP_API_URL}/routers/${id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching router!');
    }
  }
);

export const patchRouterDetails = createAsyncThunk<
  MicroserviceRouterType,
  { id: number; data: UpdateMicroserviceRouterDto },
  { state: RootState }
>('routers/patchRouterDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<MicroserviceRouterType>(`${process.env.REACT_APP_API_URL}/routers/${id}`, data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating router!');
  }
});

export const deleteRouterDetails = createAsyncThunk<
  { success: true },
  { id: number; fetchList: boolean },
  { state: RootState }
>('routers/deleteRouterDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<{ success: true }>(`${process.env.REACT_APP_API_URL}/routers/${id}`);
    if (fetchList) thunkAPI.dispatch(getRoutersList(thunkAPI.getState().routersReducer.routersRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting router!');
  }
});

export const updateRouterCurrentStatus = createAsyncThunk<
  MicroserviceRouterType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('routers/updateRouterCurrentStatus', async ({ id, fetchList }, thunkAPI) => {
  try {
    if (fetchList) thunkAPI.dispatch(getRoutersList(thunkAPI.getState().routersReducer.routersRouteParams));

    const response = await axios.patch<MicroserviceRouterType>(
      `${process.env.REACT_APP_API_URL}/routers/${id}/update-current-status`,
      null
    );
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating router current status!');
  }
});

export const getRouterInterfaceStats = createAsyncThunk<
  InterfaceRouterStatus,
  { id: number; end_date?: string; start_date?: string; range?: number; type: string },
  { state: RootState }
>('routers/getRouterInterfaceStats', async ({ id, end_date, range, type, start_date }, thunkAPI) => {
  try {
    const params = { end_date, start_date, range, type };
    const queryString = Object.keys(params)
      .map(key => {
        if (params[key]) return key + '=' + params[key];
        return key + '=';
      })
      .join('&');

    const response = await axios.get<InterfaceRouterStatus>(
      `${process.env.REACT_APP_API_URL}/routers/interface/${id}/stats${queryString ? `?${queryString}` : ''}`
    );

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching interface stats!');
  }
});
