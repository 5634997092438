import { createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import snackbarUtils from '../../utils/SnackbarUtils';
import { getSalesReportsData } from './salesReport.action';

export interface SalesReportsState {
  salesReportsData: null | Array<{
    date: string;
    serviceTypeId: number;
    orgId: number;
    activatedCount: number;
    disconnectedCount: number;
  }>;
  salesReportsDataFetchStatus: FETCH_STATUS;
}

const INIT_STATE: SalesReportsState = {
  salesReportsData: null,
  salesReportsDataFetchStatus: FETCH_STATUS.NULL,
};

export const salesReportsSlice = createSlice({
  name: 'salesReports',
  initialState: INIT_STATE,
  reducers: {
    setSalesReportsData: (state, action) => {
      state.salesReportsData = action.payload;
    },
    setSalesReportsDataFetchStatus: (state, action) => {
      state.salesReportsDataFetchStatus = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(getSalesReportsData.pending, state => {
      state.salesReportsDataFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSalesReportsData.rejected, (state, action) => {
      state.salesReportsDataFetchStatus = FETCH_STATUS.REJECTED;
      state.salesReportsData = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getSalesReportsData.fulfilled, (state, action) => {
      state.salesReportsDataFetchStatus = FETCH_STATUS.FULFILLED;
      state.salesReportsData = action.payload;
    });
  },
});

export const { setSalesReportsDataFetchStatus, setSalesReportsData } = salesReportsSlice.actions;
export default salesReportsSlice.reducer;
