import { PermissionArrayType } from '../../../types/other';
import { REQUEST_STATUS } from '../../../types/enums/request-status.enum';
import { REQUEST_TYPE } from '../../../types/enums/request-type.enum';
import { REQUEST_TIME } from '../../../types/enums/request-time.enum';
import * as yup from 'yup';
import { PERMISSIONROLECONSTANTS } from '../../../features/utils/utils.action';

export const serviceCallRequestPermissions: PermissionArrayType = {
  CREATE: [PERMISSIONROLECONSTANTS.superadmin],
  READ: [PERMISSIONROLECONSTANTS.superadmin],
  UPDATE: [PERMISSIONROLECONSTANTS.superadmin],
  DELETE: [PERMISSIONROLECONSTANTS.superadmin],
};

export interface ServiceCallRequestForm {
  id: string | null;
  organization: null | { id: number };
  location: null | { id: number };
  requestStatus: null | { id: REQUEST_STATUS };
  requestType: null | { id: REQUEST_TYPE };
  customDescription: null | string;
  timeslots: Array<{
    date: string;
    time: null | { id: REQUEST_TIME };
  }>;
  isSiteSurveyNeeded: null | { id: boolean };
  isSiteUnderConstruction: null | { id: boolean };
}

export const serviceCallRequestValidationSchema = yup.object({
  organization: yup.mixed().required('Required'),
  location: yup.mixed().required('Required'),
  requestType: yup.mixed().required('Required'),
  timeslots: yup.array().of(
    yup.object().shape({
      date: yup.string().required('Required'),
      time: yup.mixed().required('Required'),
    })
  ),
  customDescription: yup.string().when(['requestType'], {
    is: (requestType: { id: REQUEST_TYPE }) => requestType?.id === REQUEST_TYPE.CUSTOM,
    then: yup.string().nullable(true).required('Required'),
    otherwise: yup.string().nullable(true),
  }),
  isSiteSurveyNeeded: yup.mixed().required('Required'),
  isSiteUnderConstruction: yup.mixed().required('Required'),
});
