import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import { DATE_RANGE } from '../../utils/constants';
import moment from 'moment';

export const getSalesReportsData = createAsyncThunk<
  Array<{
    date: string;
    serviceTypeId: number;
    orgId: number;
    activatedCount: number;
    disconnectedCount: number;
  }>,
  {
    data: {
      organizations: Array<number>;
      allSubOrgs: boolean;
      allResellers: boolean;
      allServices: boolean;
      serviceTypes: Array<number>;
      type: DATE_RANGE;
      date?: string;
      startDate?: string;
      endDate?: string;
    };
  },
  { state: RootState }
>('salesReports/getSalesReportsData', async ({ data }, thunkAPI) => {
  try {
    if (!data.endDate) {
      delete data.endDate;
    } else {
      data.endDate = moment(new Date(data.endDate)).format('YYYY-MM-DD');
    }

    if (!data.startDate) {
      delete data.startDate;
    } else {
      data.startDate = moment(new Date(data.startDate)).format('YYYY-MM-DD');
    }

    if (!data.date) {
      delete data.date;
    } else {
      data.date = moment(new Date(data.date)).format('YYYY-MM-DD');
    }

    const queryString = Object.keys(data)
      .map(key => key + '=' + data[key])
      .join('&');

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/sales-reports${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching sales report!');
  }
});
