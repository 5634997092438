import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../../features/utils/utils.slice';
import {
  CreatePbxExtensionDto,
  PbxExtensionDetailsType,
  PbxExtensionListItemType,
  UpdatePbxExtensionDto,
} from '../../types/pbx-extension/pbx-extension.type';
import { PbxExtensionTypeType } from '../../types/pbx-extension-type/pbx-extension-type.type';

export const getPbxExtensionsList = createAsyncThunk<
  PaginationType<PbxExtensionListItemType>,
  RouteParamsType,
  { state: RootState }
>('pbxExtensions/getPbxExtensionsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<PbxExtensionListItemType>>(
      `${process.env.REACT_APP_API_URL}/pbx-extensions${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching PBX Extensions!');
  }
});

export const getPbxExtensionTypesList = createAsyncThunk<
  Array<PbxExtensionTypeType>,
  { org_id: number },
  { state: RootState }
>('pbxExtensions/getPbxExtensionTypesList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<Array<PbxExtensionTypeType>>(
      `${process.env.REACT_APP_API_URL}/pbx-extensions/type/${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching PBX Extension Types!');
  }
});

export const getFullPbxExtensionTypesList = createAsyncThunk<Array<PbxExtensionTypeType>, void, { state: RootState }>(
  'pbxExtensions/getFullPbxExtensionTypesList',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get<Array<PbxExtensionTypeType>>(
        `${process.env.REACT_APP_API_URL}/pbx-extensions/type/`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching PBX Extension Types!');
    }
  }
);

export const postPbxExtensionOrganizationType = createAsyncThunk<
  Array<PbxExtensionTypeType>,
  { org_id: number; enable: boolean; id: number },
  { state: RootState }
>('pbxExtensions/postPbxExtensionOrganizationType', async (params, thunkAPI) => {
  try {
    const response = await axios.post<Array<PbxExtensionTypeType>>(
      `${process.env.REACT_APP_API_URL}/pbx-extensions/type/${params.id}?org_id=${params.org_id}&enable=${params.enable}`,
      null
    );

    thunkAPI.dispatch(getPbxExtensionTypesList({ org_id: params.org_id }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching PBX Extension Types!');
  }
});

export const postPBXExtensionDetails = createAsyncThunk<
  PbxExtensionDetailsType,
  { data: any },
  { state: RootState }
>('pbxExtensions/postPBXExtensionDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<PbxExtensionDetailsType>(`${process.env.REACT_APP_API_URL}/pbx-extensions`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating PBX Extension!');
  }
});

export const getPbxExtensionDetails = createAsyncThunk<PbxExtensionDetailsType, { id: number }, { state: RootState }>(
  'pbxExtensions/getPbxExtensionDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<PbxExtensionDetailsType>(
        `${process.env.REACT_APP_API_URL}/pbx-extensions/${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching PBX Extension!');
    }
  }
);

export const patchPbxExtensionDetails = createAsyncThunk<
  PbxExtensionDetailsType,
  { id: number; data: any },
  { state: RootState }
>('pbxExtensions/patchPbxExtensionDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<PbxExtensionDetailsType>(
      `${process.env.REACT_APP_API_URL}/pbx-extensions/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating PBX Extension!');
  }
});

export const deletePbxExtensionDetails = createAsyncThunk<
  PbxExtensionDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('pbxExtensions/deletePbxExtensionDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<PbxExtensionDetailsType>(
      `${process.env.REACT_APP_API_URL}/pbx-extensions/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(getPbxExtensionsList(thunkAPI.getState().pbxExtensionsReducer.pbxExtensionsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting PBX Extension!');
  }
});
