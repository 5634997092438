export enum REQUEST_STATUS {
  REQUESTED = 1,
  SCHEDULED = 2,
  IN_PROGRESS = 3,
  CANCELED = 4,
  COMPLETED = 5,
}

export const requestStatusOptions = [
  { id: REQUEST_STATUS.REQUESTED, name: 'Requested' },
  { id: REQUEST_STATUS.SCHEDULED, name: 'Scheduled' },
  { id: REQUEST_STATUS.IN_PROGRESS, name: 'In progress' },
  { id: REQUEST_STATUS.CANCELED, name: 'Canceled' },
  { id: REQUEST_STATUS.COMPLETED, name: 'Completed' },
];
