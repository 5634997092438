import { combineReducers } from 'redux';
import pbxsReducer from '../features/pbxs/pbxs.slice';
import ordersReducer from '../features/orders/orders.slice';
import enclosuresReducer from '../features/enclosures/enclosures.slice';
import portingsReducer from '../features/porting/porting.slice';
import inventoriesReducer from '../features/inventories/inventories.slice';
import dataPlansReducer from '../features/dataPlans/dataPlans.slice';
import countriesReducer from '../features/countries/countries.slice';
import smsLoggerReducer from '../features/smsLogger/smsLogger.slice';
import notificationsReducer from '../features/notifications/notifications.slice';
import externalSystemsReducer from '../features/externalSystems/externalSystems.slice';
import cdrsReducer from '../features/cdrs/cdrs.slice';
import ticketsReducer from '../features/tickets/tickets.slice';
import addressesReducer from '../features/addresses/addresses.slice';
import logsReducer from '../features/logs/logs.slice';
import secureAlarmReducer from '../features/secureAlarm/secureAlarm.slice';
import warehousesReducer from '../features/warehouses/warehouses.slice';
import batteriesReducer from '../features/batteries/batteries.slice';
import dialersReducer from '../features/dialers/dialers.slice';
import powerCyclesReducer from '../features/powerCycles/powerCycles.slice';
import pbxExtensionsReducer from '../features/pbxExtensions/pbxExtensions.slice';
import didsReducer from '../features/dids/dids.slice';
import contactIDMessagesReducer from '../features/contactIDMessages/contactIDMessages.slice';
import organizationsReducer from '../features/organizations/organizations.slice';
import didManagerReducer from '../features/didManager/didManager.slice';
import tcServicesReducer from '../features/tcServices/tcServices.slice';
import routersReducer from '../features/routers/routers.slice';
import sharedReducer from '../features/shared/shared.slice';
import authReducer from '../features/auth/auth.slice';
import equipmentGroupsReducer from '../features/equipmentGroups/equipmentGroups.slice';
import locationsReducer from '../features/locations/locations.slice';
import atasReducer from '../features/atas/atas.slice';
import usersReducer from '../features/users/users.slice';
import utilsReducer from '../features/utils/utils.slice';
import tcSimsReducer from '../features/tcSims/tcSims.slice';
import releaseNotesReducer from '../features/releaseNotes/releaseNotes.slice';
import contactsReducer from '../features/contacts/contacts.slice';
import salesReportsReducer from '../features/salesReports/salesReport.slice';
import accessAndVisibilityReducer from '../features/accessAndVisibility/accessAndVisibility.slice';
import serviceCallReducer from '../features/serviceCall/serviceCall.slice';

const reducers = combineReducers({
  serviceCallReducer,
  contactsReducer,
  pbxsReducer,
  ticketsReducer,
  ordersReducer,
  enclosuresReducer,
  portingsReducer,
  inventoriesReducer,
  dataPlansReducer,
  countriesReducer,
  smsLoggerReducer,
  tcSimsReducer,
  utilsReducer,
  usersReducer,
  equipmentGroupsReducer,
  externalSystemsReducer,
  organizationsReducer,
  locationsReducer,
  didsReducer,
  notificationsReducer,
  cdrsReducer,
  addressesReducer,
  logsReducer,
  secureAlarmReducer,
  warehousesReducer,
  batteriesReducer,
  dialersReducer,
  powerCyclesReducer,
  pbxExtensionsReducer,
  contactIDMessagesReducer,
  didManagerReducer,
  tcServicesReducer,
  routersReducer,
  sharedReducer,
  atasReducer,
  authReducer,
  releaseNotesReducer,
  salesReportsReducer,
  accessAndVisibilityReducer,
});

export default reducers;
