import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  OrganizationDetailsType,
  OrganizationListItemType,
  OrganizationMapStatsType,
} from '../../types/organization/organization.type';
import {
  deleteOrganizationDetails,
  deleteOrgFile,
  deleteOrgNote,
  getOrganizationDetails,
  getOrganizationMapStats,
  getOrganizationsList,
  patchOrganizationBobo,
  patchOrganizationDetails,
  patchOrgFile,
  patchOrgNote,
  patchRemoveOrganizationCredentials,
  postFixOrganizationRevIo,
  postMultipleOrgFiles,
  postOrganizationDetails,
  postOrgNote,
  postVerifyOrganizationCredentials,
} from './organizations.action';

export interface OrganizationsState {
  organizationsList: null | PaginationType<OrganizationListItemType>;
  organizationsListFetchStatus: FETCH_STATUS;
  organizationsRouteParams: RouteParamsType;
  organizationDetails: null | OrganizationDetailsType;
  organizationDetailsFetchStatus: FETCH_STATUS;
  organizationDetailsPostStatus: FETCH_STATUS;
  organizationDetailsPatchStatus: FETCH_STATUS;
  organizationDetailsDeleteStatus: FETCH_STATUS;
  organizationMapStats: null | OrganizationMapStatsType;
  organizationMapStatsFetchStatus: FETCH_STATUS;
  organizationBoboPatchStatus: FETCH_STATUS;
  removeOrganizationCredentialsPatchStatus: FETCH_STATUS;
  verifyOrganizationCredentialsPostStatus: FETCH_STATUS;
  updateOrganizationRouterStatuesFetchStatus: FETCH_STATUS;
  fixOrganizationRevIoPostStatus: FETCH_STATUS;
  orgFilePatchStatus: FETCH_STATUS;
  orgFilePostStatus: FETCH_STATUS;
  orgFileDeleteStatus: FETCH_STATUS;
  orgNotePatchStatus: FETCH_STATUS;
  orgNotePostStatus: FETCH_STATUS;
  orgNoteDeleteStatus: FETCH_STATUS;
}

export const initialOrganizationsRouteParams = {
  page: 1,
  limit: 10,
  order: '-id',
  q: '',
  is_active: 2,
  is_open: 2,
  org_type: 1,
  country_id: '',
  country_category: '',
};

const INIT_STATE: OrganizationsState = {
  organizationsRouteParams: initialOrganizationsRouteParams,
  organizationsList: null,
  organizationsListFetchStatus: FETCH_STATUS.NULL,
  organizationDetails: null,
  organizationDetailsFetchStatus: FETCH_STATUS.NULL,
  organizationDetailsPostStatus: FETCH_STATUS.NULL,
  organizationDetailsPatchStatus: FETCH_STATUS.NULL,
  organizationDetailsDeleteStatus: FETCH_STATUS.NULL,
  organizationMapStats: null,
  organizationMapStatsFetchStatus: FETCH_STATUS.NULL,
  organizationBoboPatchStatus: FETCH_STATUS.NULL,
  removeOrganizationCredentialsPatchStatus: FETCH_STATUS.NULL,
  verifyOrganizationCredentialsPostStatus: FETCH_STATUS.NULL,
  updateOrganizationRouterStatuesFetchStatus: FETCH_STATUS.NULL,
  fixOrganizationRevIoPostStatus: FETCH_STATUS.NULL,
  orgFilePatchStatus: FETCH_STATUS.NULL,
  orgFilePostStatus: FETCH_STATUS.NULL,
  orgFileDeleteStatus: FETCH_STATUS.NULL,
  orgNotePatchStatus: FETCH_STATUS.NULL,
  orgNotePostStatus: FETCH_STATUS.NULL,
  orgNoteDeleteStatus: FETCH_STATUS.NULL,
};

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: INIT_STATE,
  reducers: {
    setOrganizationsList: (state, action) => {
      state.organizationsList = action.payload;
    },
    setOrganizationsListFetchStatus: (state, action) => {
      state.organizationsListFetchStatus = action.payload;
    },
    setOrganizationDetails: (state, action) => {
      state.organizationDetails = action.payload;
    },
    setOrganizationDetailsFetchStatus: (state, action) => {
      state.organizationDetailsFetchStatus = action.payload;
    },
    setOrganizationDetailsPostStatus: (state, action) => {
      state.organizationDetailsPostStatus = action.payload;
    },
    setOrganizationDetailsPatchStatus: (state, action) => {
      state.organizationDetailsPatchStatus = action.payload;
    },
    setOrganizationDetailsDeleteStatus: (state, action) => {
      state.organizationDetailsDeleteStatus = action.payload;
    },
    setOrganizationMapStats: (state, action) => {
      state.organizationMapStats = action.payload;
    },
    setOrganizationMapStatsFetchStatus: (state, action) => {
      state.organizationMapStatsFetchStatus = action.payload;
    },
    setOrganizationBoboPatchStatus: (state, action) => {
      state.organizationBoboPatchStatus = action.payload;
    },
    setRemoveOrganizationCredentialsPatchStatus: (state, action) => {
      state.removeOrganizationCredentialsPatchStatus = action.payload;
    },
    setVerifyOrganizationCredentialsPostStatus: (state, action) => {
      state.verifyOrganizationCredentialsPostStatus = action.payload;
    },
    setUpdateOrganizationRouterStatuesFetchStatus: (state, action) => {
      state.updateOrganizationRouterStatuesFetchStatus = action.payload;
    },
    setFixOrganizationRevIoPostStatus: (state, action) => {
      state.fixOrganizationRevIoPostStatus = action.payload;
    },
    setOrgFileDeleteStatus: (state, action) => {
      state.orgFileDeleteStatus = action.payload;
    },
    setOrgFilePostStatus: (state, action) => {
      state.orgFilePostStatus = action.payload;
    },
    setOrgFilePatchStatus: (state, action) => {
      state.orgFilePatchStatus = action.payload;
    },
    setOrgNoteDeleteStatus: (state, action) => {
      state.orgNoteDeleteStatus = action.payload;
    },
    setOrgNotePatchStatus: (state, action) => {
      state.orgNotePatchStatus = action.payload;
    },
    setOrgNotePostStatus: (state, action) => {
      state.orgNotePostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'organizations') {
        state.organizationsRouteParams = { ...state.organizationsRouteParams, ...payload.data };
      }
    });

    // CREATE ORGANIZATIONS DETAILS
    builder.addCase(postOrganizationDetails.pending, state => {
      state.organizationDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postOrganizationDetails.rejected, (state, action) => {
      state.organizationDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postOrganizationDetails.fulfilled, state => {
      state.organizationDetailsPostStatus = FETCH_STATUS.FULFILLED;
    });

    // GET ORGANIZATIONS LIST
    builder.addCase(getOrganizationsList.pending, state => {
      state.organizationsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getOrganizationsList.rejected, (state, action) => {
      state.organizationsListFetchStatus = FETCH_STATUS.REJECTED;
      state.organizationsList = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getOrganizationsList.fulfilled, (state, action) => {
      state.organizationsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.organizationsList = action.payload;
    });

    // GET ORGANIZATIONS DETAILS
    builder.addCase(getOrganizationDetails.pending, state => {
      state.organizationDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getOrganizationDetails.rejected, (state, action) => {
      state.organizationDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.organizationDetails = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getOrganizationDetails.fulfilled, (state, action) => {
      state.organizationDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.organizationDetails = action.payload;
    });

    // PATCH ORGANIZATIONS DETAILS
    builder.addCase(patchOrganizationDetails.pending, state => {
      state.organizationDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchOrganizationDetails.rejected, (state, action) => {
      state.organizationDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchOrganizationDetails.fulfilled, (state, action) => {
      if (action.payload?.alerts?.parentHasChanged) {
        setTimeout(() =>
          snackbarUtils.info(
            'The parent organization has changed. Make sure to delete the prev organization in the Rev.io portal'
          )
        );
      } else {
        setTimeout(() => snackbarUtils.success('Organization updated successfully'));
      }
      state.organizationDetailsPatchStatus = FETCH_STATUS.FULFILLED;
    });

    // DELETE ORGANIZATIONS DETAILS
    builder.addCase(deleteOrganizationDetails.pending, state => {
      state.organizationDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteOrganizationDetails.rejected, (state, action) => {
      state.organizationDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteOrganizationDetails.fulfilled, state => {
      state.organizationDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
    });

    // GET ORGANIZATIONS MAP STATS
    builder.addCase(getOrganizationMapStats.pending, state => {
      state.organizationMapStatsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getOrganizationMapStats.rejected, (state, action) => {
      state.organizationMapStatsFetchStatus = FETCH_STATUS.REJECTED;
      state.organizationMapStats = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getOrganizationMapStats.fulfilled, (state, action) => {
      state.organizationMapStatsFetchStatus = FETCH_STATUS.FULFILLED;
      state.organizationMapStats = action.payload;
    });

    // PATCH ORGANIZATION BOBO
    builder.addCase(patchOrganizationBobo.pending, state => {
      state.organizationBoboPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchOrganizationBobo.rejected, (state, action) => {
      state.organizationBoboPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchOrganizationBobo.fulfilled, state => {
      state.organizationBoboPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Organization updated successfully'));
    });

    // PATCH REMOVE ORGANIZATION CREDENTIALS
    builder.addCase(patchRemoveOrganizationCredentials.pending, state => {
      state.removeOrganizationCredentialsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchRemoveOrganizationCredentials.rejected, (state, action) => {
      state.removeOrganizationCredentialsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchRemoveOrganizationCredentials.fulfilled, state => {
      state.removeOrganizationCredentialsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Credentials are removed successfully'));
    });

    // POST VERIFY ORGANIZATION CREDENTIALS
    builder.addCase(postVerifyOrganizationCredentials.pending, state => {
      state.verifyOrganizationCredentialsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postVerifyOrganizationCredentials.rejected, (state, action) => {
      state.verifyOrganizationCredentialsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postVerifyOrganizationCredentials.fulfilled, state => {
      state.verifyOrganizationCredentialsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Credentials are verified successfully'));
    });

    // POST FIX REV.IO FOR ORGANIZATION
    builder.addCase(postFixOrganizationRevIo.pending, state => {
      state.fixOrganizationRevIoPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postFixOrganizationRevIo.rejected, (state, action) => {
      state.fixOrganizationRevIoPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postFixOrganizationRevIo.fulfilled, state => {
      state.fixOrganizationRevIoPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() =>
        snackbarUtils.success('Organization Rev.io fix has started successfully. Check back in a moment')
      );
    });

    // POST MULTIPLE FILES
    builder.addCase(postMultipleOrgFiles.pending, state => {
      state.orgFilePostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postMultipleOrgFiles.rejected, (state, action) => {
      state.orgFilePostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postMultipleOrgFiles.fulfilled, state => {
      state.orgFilePostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('File has been uploaded!'));
    });

    // PATCH MULTIPLE FILES
    builder.addCase(patchOrgFile.pending, state => {
      state.orgFilePatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchOrgFile.rejected, (state, action) => {
      state.orgFilePatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchOrgFile.fulfilled, state => {
      state.orgFilePatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('File has been updated!'));
    });

    // DELETE MULTIPLE FILES
    builder.addCase(deleteOrgFile.pending, state => {
      state.orgFileDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteOrgFile.rejected, (state, action) => {
      state.orgFileDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteOrgFile.fulfilled, state => {
      state.orgFileDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('File has been deleted!'));
    });

    // POST ORG NOTE
    builder.addCase(postOrgNote.pending, state => {
      state.orgNotePostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postOrgNote.rejected, (state, action) => {
      state.orgNotePostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postOrgNote.fulfilled, state => {
      state.orgNotePostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Note has been added!'));
    });

    // PATCH ORG NOTE
    builder.addCase(patchOrgNote.pending, state => {
      state.orgNotePatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchOrgNote.rejected, (state, action) => {
      state.orgNotePatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchOrgNote.fulfilled, state => {
      state.orgNotePatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Note has been changed!'));
    });

    // DELETE ORG NOTE
    builder.addCase(deleteOrgNote.pending, state => {
      state.orgNoteDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteOrgNote.rejected, (state, action) => {
      state.orgNoteDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteOrgNote.fulfilled, state => {
      state.orgNoteDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Note has been removed!'));
    });
  },
});

export const {
  setOrganizationsList,
  setOrganizationsListFetchStatus,
  setOrganizationDetailsFetchStatus,
  setOrganizationDetailsDeleteStatus,
  setOrganizationDetailsPatchStatus,
  setOrganizationDetailsPostStatus,
  setOrganizationDetails,
  setOrganizationMapStats,
  setOrganizationMapStatsFetchStatus,
  setRemoveOrganizationCredentialsPatchStatus,
  setUpdateOrganizationRouterStatuesFetchStatus,
  setVerifyOrganizationCredentialsPostStatus,
  setOrganizationBoboPatchStatus,
  setFixOrganizationRevIoPostStatus,
  setOrgFilePatchStatus,
  setOrgNoteDeleteStatus,
  setOrgFilePostStatus,
  setOrgNotePatchStatus,
  setOrgNotePostStatus,
  setOrgFileDeleteStatus,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
