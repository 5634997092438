import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouterConnectionType, RouterIntegrationType } from '../../types/router-connection/router-connection.type';
import { createSlice } from '@reduxjs/toolkit';
import {
  deleteRouterConnection,
  deleteRouterDetails,
  deleteSyncRouter,
  getRouterConnectionList,
  getRouterDetails,
  getRouterIntegrationsList,
  getRouterInterfaceStats,
  getRoutersList,
  getSyncRouters,
  patchRouterDetails,
  patchSyncRouters,
  postRouterConnection,
  postRouterDetails,
  postRoutersDetails,
  postSyncExternalRouters,
  postSyncRouters,
  updateRouterCurrentStatus,
} from './routers.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { PaginationType } from '../../types/utils/pagination.type';
import { SyncRouterType } from '../../types/router/sync-router/sync-router.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { InterfaceRouterStatus, MicroserviceRouterType } from '../../types/router/router.type';

export interface RoutersState {
  routersList: PaginationType<MicroserviceRouterType> | null;
  routersListFetchStatus: FETCH_STATUS;
  routersRouteParams: RouteParamsType;
  routerDetails: MicroserviceRouterType | null;
  routerDetailsPostStatus: FETCH_STATUS;
  routerDetailsPatchStatus: FETCH_STATUS;
  routerDetailsDeleteStatus: FETCH_STATUS;
  routerDetailsFetchStatus: FETCH_STATUS;
  updateRouterDetailsPatchStatus: FETCH_STATUS;
  routerInterfaceStats: null | InterfaceRouterStatus;
  routerInterfaceStatsFetchStatus: FETCH_STATUS;

  routerConnectionsList: null | Array<RouterConnectionType>;
  routerConnectionsFetchStatus: FETCH_STATUS;
  routerConnectionsPostStatus: FETCH_STATUS;
  routerConnectionsDeleteStatus: FETCH_STATUS;

  routerIntegrationsList: null | Array<RouterIntegrationType>;
  routerIntegrationsFetchStatus: FETCH_STATUS;

  syncExternalRoutersPostStatus: FETCH_STATUS;
  syncRouterListFetchStatus: FETCH_STATUS;
  noSyncRouterListFetchStatus: FETCH_STATUS;
  syncRouterList: null | PaginationType<SyncRouterType>;
  noSyncRouterList: null | PaginationType<SyncRouterType>;
  syncRouterListParams: { connectionId: number; limit: number; page: number; query: string; isSynced: boolean };
  noSyncRouterListParams: { connectionId: number; limit: number; page: number; query: string; isSynced: boolean };
  syncRouterDeleteStatus: FETCH_STATUS;
  syncRoutersPostStatus: FETCH_STATUS;
  syncRoutersPatchStatus: FETCH_STATUS;

  routersPostStatus: FETCH_STATUS;
  routersBulk: null | Array<{ serial_number: string; imei: string; product_name: string }>;
}

export const initialRoutersRouteParams = {
  page: 1,
  limit: 10,
  order: '-router.id',
  q: '',
  country_id: '',
  country_category: '',
};

export const initSyncRouterListParams: {
  connectionId: number;
  limit: number;
  page: number;
  query: string;
  isSynced: boolean;
  order: 'id';
  q: string;
} = {
  connectionId: 0,
  limit: 10,
  page: 1,
  query: '',
  isSynced: true,
  order: 'id',
  q: '',
};

export const initNoSyncRouterListParams: {
  connectionId: number;
  limit: number;
  page: number;
  query: string;
  isSynced: boolean;
  order: 'id';
  q: string;
} = {
  connectionId: 0,
  limit: 10,
  page: 1,
  query: '',
  isSynced: false,
  order: 'id',
  q: '',
};

const INIT_STATE: RoutersState = {
  routersList: null,
  routersListFetchStatus: FETCH_STATUS.NULL,
  routersRouteParams: initialRoutersRouteParams,
  routerDetailsFetchStatus: FETCH_STATUS.NULL,
  routerDetailsPostStatus: FETCH_STATUS.NULL,
  routerDetailsPatchStatus: FETCH_STATUS.NULL,
  routerDetailsDeleteStatus: FETCH_STATUS.NULL,
  updateRouterDetailsPatchStatus: FETCH_STATUS.NULL,
  routerDetails: null,
  routerInterfaceStats: null,
  routerInterfaceStatsFetchStatus: FETCH_STATUS.NULL,

  routerConnectionsList: null,
  routerConnectionsFetchStatus: FETCH_STATUS.NULL,
  routerConnectionsPostStatus: FETCH_STATUS.NULL,
  routerConnectionsDeleteStatus: FETCH_STATUS.NULL,

  routerIntegrationsList: null,
  routerIntegrationsFetchStatus: FETCH_STATUS.NULL,

  syncExternalRoutersPostStatus: FETCH_STATUS.NULL,
  syncRouterListFetchStatus: FETCH_STATUS.NULL,
  syncRouterList: null,
  syncRouterListParams: initSyncRouterListParams,
  noSyncRouterListFetchStatus: FETCH_STATUS.NULL,
  noSyncRouterList: null,
  noSyncRouterListParams: initNoSyncRouterListParams,
  syncRouterDeleteStatus: FETCH_STATUS.NULL,
  syncRoutersPostStatus: FETCH_STATUS.NULL,
  syncRoutersPatchStatus: FETCH_STATUS.NULL,

  routersPostStatus: FETCH_STATUS.NULL,
  routersBulk: null,
};

export const routersSlice = createSlice({
  name: 'routers',
  initialState: INIT_STATE,
  reducers: {
    setRouterConnectionsList: (state, action) => {
      state.routerConnectionsList = action.payload;
    },
    setRouterConnectionsFetchStatus: (state, action) => {
      state.routerConnectionsFetchStatus = action.payload;
    },
    setRouterConnectionsPostStatus: (state, action) => {
      state.routerConnectionsPostStatus = action.payload;
    },
    setRouterConnectionsDeleteStatus: (state, action) => {
      state.routerConnectionsDeleteStatus = action.payload;
    },
    setRouterIntegrationsList: (state, action) => {
      state.routerIntegrationsList = action.payload;
    },
    setRouterIntegrationsFetchStatus: (state, action) => {
      state.routerIntegrationsFetchStatus = action.payload;
    },
    setSyncExternalRoutersPostStatus: (state, action) => {
      state.syncExternalRoutersPostStatus = action.payload;
    },
    setSyncRouterListFetchStatus: (state, action) => {
      state.syncRouterListFetchStatus = action.payload;
    },
    setNoSyncRouterListFetchStatus: (state, action) => {
      state.noSyncRouterListFetchStatus = action.payload;
    },
    setSyncRouterList: (state, action) => {
      state.syncRouterList = action.payload;
    },
    setNoSyncRouterList: (state, action) => {
      state.noSyncRouterList = action.payload;
    },
    setSyncRouterDeleteStatus: (state, action) => {
      state.syncRouterDeleteStatus = action.payload;
    },
    setSyncRouterListParams: (state, action) => {
      state.syncRouterListParams = action.payload;
    },
    setNoSyncRouterListParams: (state, action) => {
      state.noSyncRouterListParams = action.payload;
    },
    setSyncRoutersPostStatus: (state, action) => {
      state.syncRoutersPostStatus = action.payload;
    },
    setSyncRoutersPatchStatus: (state, action) => {
      state.syncRoutersPatchStatus = action.payload;
    },
    setRoutersList: (state, action) => {
      state.routersList = action.payload;
    },
    setRoutersListFetchStatus: (state, action) => {
      state.routersListFetchStatus = action.payload;
    },
    setRouterDetails: (state, action) => {
      state.routerDetails = action.payload;
    },
    setRouterDetailsFetchStatus: (state, action) => {
      state.routerDetailsFetchStatus = action.payload;
    },
    setRouterDetailsPostStatus: (state, action) => {
      state.routerDetailsPostStatus = action.payload;
    },
    setRouterDetailsPatchStatus: (state, action) => {
      state.routerDetailsPatchStatus = action.payload;
    },
    setRouterDetailsDeleteStatus: (state, action) => {
      state.routerDetailsDeleteStatus = action.payload;
    },
    setUpdateRouterDetailsPatchStatus: (state, action) => {
      state.updateRouterDetailsPatchStatus = action.payload;
    },
    setRouterInterfaceStats: (state, action) => {
      state.routerInterfaceStats = action.payload;
    },
    setRouterInterfaceStatsFetchStatus: (state, action) => {
      state.routerInterfaceStatsFetchStatus = action.payload;
    },
    setRoutersBulk: (state, action) => {
      state.routersBulk = action.payload;
    },
    setRoutersPostStatus: (state, action) => {
      state.routersPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      const data = { ...payload.data };
      data.limit = +payload?.data?.limit;

      if (payload.reducer === 'noSyncRouters') {
        state.noSyncRouterListParams = { ...state.noSyncRouterListParams, ...data };
      }

      if (payload.reducer === 'syncRouters') {
        state.syncRouterListParams = { ...state.syncRouterListParams, ...data };
      }

      if (payload.reducer === 'microserviceRouters') {
        state.routersRouteParams = { ...state.routersRouteParams, ...data };
      }
    });

    // GET ROUTER CONNECTIONS
    builder.addCase(getRouterConnectionList.pending, state => {
      state.routerConnectionsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRouterConnectionList.rejected, (state, action) => {
      state.routerConnectionsFetchStatus = FETCH_STATUS.REJECTED;
      state.routerConnectionsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRouterConnectionList.fulfilled, (state, action) => {
      state.routerConnectionsFetchStatus = FETCH_STATUS.FULFILLED;
      state.routerConnectionsList = action.payload;
    });

    // POST ROUTER CONNECTION
    builder.addCase(postRouterConnection.pending, state => {
      state.routerConnectionsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postRouterConnection.rejected, (state, action) => {
      state.routerConnectionsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postRouterConnection.fulfilled, state => {
      state.routerConnectionsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Connection created successfully');
      });
    });

    // DELETE ROUTER CONNECTION
    builder.addCase(deleteRouterConnection.pending, state => {
      state.routerConnectionsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteRouterConnection.rejected, (state, action) => {
      state.routerConnectionsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteRouterConnection.fulfilled, state => {
      state.routerConnectionsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Connection deleted successfully');
      });
    });

    // GET ROUTER INTEGRATIONS
    builder.addCase(getRouterIntegrationsList.pending, state => {
      state.routerIntegrationsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRouterIntegrationsList.rejected, (state, action) => {
      state.routerIntegrationsFetchStatus = FETCH_STATUS.REJECTED;
      state.routerIntegrationsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRouterIntegrationsList.fulfilled, (state, action) => {
      state.routerIntegrationsFetchStatus = FETCH_STATUS.FULFILLED;
      state.routerIntegrationsList = action.payload;
    });

    // Sync External Routers
    builder.addCase(postSyncExternalRouters.pending, state => {
      state.syncExternalRoutersPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postSyncExternalRouters.rejected, (state, action) => {
      state.syncExternalRoutersPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postSyncExternalRouters.fulfilled, state => {
      state.syncExternalRoutersPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Routers synced has been started successfully!');
      });
    });

    // GET SYNC ROUTERS
    builder.addCase(getSyncRouters.pending, (state, action) => {
      const dto = action.meta.arg as { isSynced: boolean };
      if (dto.isSynced) {
        state.syncRouterListFetchStatus = FETCH_STATUS.PENDING;
      } else {
        state.noSyncRouterListFetchStatus = FETCH_STATUS.PENDING;
      }
    });
    builder.addCase(getSyncRouters.rejected, (state, action) => {
      const dto = action.meta.arg as { isSynced: boolean };
      if (dto.isSynced) {
        state.syncRouterListFetchStatus = FETCH_STATUS.REJECTED;
      } else {
        state.noSyncRouterListFetchStatus = FETCH_STATUS.REJECTED;
      }
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getSyncRouters.fulfilled, (state, action) => {
      const dto = action.meta.arg as { isSynced: boolean };
      if (dto.isSynced) {
        state.syncRouterListFetchStatus = FETCH_STATUS.FULFILLED;
        state.syncRouterList = action.payload;
      } else {
        state.noSyncRouterListFetchStatus = FETCH_STATUS.FULFILLED;
        state.noSyncRouterList = action.payload;
      }
    });

    // DELETE SYNC ROUTER
    builder.addCase(deleteSyncRouter.pending, state => {
      state.syncRouterDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteSyncRouter.rejected, (state, action) => {
      state.syncRouterDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteSyncRouter.fulfilled, state => {
      state.syncRouterDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Sync Router deleted successfully');
      });
    });

    // POST SYNC ROUTERS
    builder.addCase(postSyncRouters.pending, state => {
      state.syncRoutersPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postSyncRouters.rejected, (state, action) => {
      state.syncRoutersPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postSyncRouters.fulfilled, state => {
      state.syncRoutersPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Routers synced started successfully!');
      });
    });

    // PATCH SYNC ROUTERS
    builder.addCase(patchSyncRouters.pending, state => {
      state.syncRoutersPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchSyncRouters.rejected, (state, action) => {
      state.syncRoutersPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchSyncRouters.fulfilled, state => {
      state.syncRoutersPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Routers synced started successfully!');
      });
    });

    // GET ROUTERS LIST
    builder.addCase(getRoutersList.pending, state => {
      state.routersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRoutersList.rejected, (state, action) => {
      state.routersListFetchStatus = FETCH_STATUS.REJECTED;
      state.routersList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRoutersList.fulfilled, (state, action) => {
      state.routersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.routersList = action.payload;
    });

    // POST ROUTER DETAILS
    builder.addCase(postRouterDetails.pending, state => {
      state.routerDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postRouterDetails.rejected, (state, action) => {
      state.routerDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postRouterDetails.fulfilled, state => {
      state.routerDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Router created successfully');
      });
    });

    // GET ROUTER DETAILS
    builder.addCase(getRouterDetails.pending, state => {
      state.routerDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRouterDetails.rejected, (state, action) => {
      state.routerDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.routerDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRouterDetails.fulfilled, (state, action) => {
      state.routerDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.routerDetails = action.payload;
    });

    // PATCH ROUTER DETAILS
    builder.addCase(patchRouterDetails.pending, state => {
      state.routerDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchRouterDetails.rejected, (state, action) => {
      state.routerDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchRouterDetails.fulfilled, state => {
      state.routerDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Router details updated successfully');
      });
    });

    // DELETE ROUTER DETAILS
    builder.addCase(deleteRouterDetails.pending, state => {
      state.routerDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteRouterDetails.rejected, (state, action) => {
      state.routerDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteRouterDetails.fulfilled, state => {
      state.routerDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Router details deleted successfully');
      });
    });

    // UPDATE ROUTER DETAILS
    builder.addCase(updateRouterCurrentStatus.pending, state => {
      state.updateRouterDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(updateRouterCurrentStatus.rejected, (state, action) => {
      state.updateRouterDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(updateRouterCurrentStatus.fulfilled, state => {
      state.updateRouterDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Router details updated successfully');
      });
    });

    // GET ROUTER INTERFACE STATS
    builder.addCase(getRouterInterfaceStats.pending, state => {
      state.routerInterfaceStatsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRouterInterfaceStats.rejected, (state, action) => {
      state.routerInterfaceStatsFetchStatus = FETCH_STATUS.REJECTED;
      state.routerInterfaceStats = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRouterInterfaceStats.fulfilled, (state, action) => {
      state.routerInterfaceStatsFetchStatus = FETCH_STATUS.FULFILLED;
      state.routerInterfaceStats = action.payload;
    });

    // POST ROUTERS
    builder.addCase(postRoutersDetails.pending, state => {
      state.routersPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postRoutersDetails.rejected, (state, action) => {
      state.routersPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postRoutersDetails.fulfilled, state => {
      state.routersPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Routers created successfully');
      });
    });
  },
});

export const {
  setRouterConnectionsList,
  setRouterConnectionsDeleteStatus,
  setRouterConnectionsPostStatus,
  setRouterConnectionsFetchStatus,
  setRouterIntegrationsList,
  setRouterIntegrationsFetchStatus,
  setSyncExternalRoutersPostStatus,
  setNoSyncRouterListFetchStatus,
  setSyncRouterListFetchStatus,
  setSyncRouterList,
  setNoSyncRouterList,
  setSyncRouterDeleteStatus,
  setSyncRouterListParams,
  setNoSyncRouterListParams,
  setSyncRoutersPostStatus,
  setSyncRoutersPatchStatus,
  setRoutersListFetchStatus,
  setRoutersList,
  setRouterDetailsFetchStatus,
  setRouterDetails,
  setRouterDetailsPostStatus,
  setRouterDetailsPatchStatus,
  setRouterDetailsDeleteStatus,
  setUpdateRouterDetailsPatchStatus,
  setRouterInterfaceStats,
  setRouterInterfaceStatsFetchStatus,
  setRoutersBulk,
  setRoutersPostStatus,
} = routersSlice.actions;

export default routersSlice.reducer;
