import { PaginationType } from '../../types/utils/pagination.type';
import { ServiceCallTechnicianType } from '../../types/service-call-technician/service-call-technician.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteServiceCallNote,
  deleteServiceCallProvider,
  deleteServiceCallRequest,
  deleteServiceCallTechnician,
  getServiceCallProviderDetails,
  getServiceCallProvidersList,
  getServiceCallRequestDetails,
  getServiceCallRequestsList,
  getServiceCallTechnicianDetails,
  getServiceCallTechniciansList,
  patchServiceCallProvider,
  patchServiceCallRequest,
  patchServiceCallTechnician,
  postServiceCallNote,
  postServiceCallProvider,
  postServiceCallRequest,
  postServiceCallTechnician,
} from './serviceCall.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { ServiceCallProviderType } from '../../types/service-call-provider/service-call-provider.type';
import { ServiceCallRequestType } from '../../types/service-call-request/service-call-request.type';

export interface ServiceCallState {
  // TECHNICIANS
  serviceCallTechniciansList: null | PaginationType<ServiceCallTechnicianType>;
  serviceCallTechniciansListFetchStatus: FETCH_STATUS;
  serviceCallTechniciansRouteParams: RouteParamsType;
  serviceCallTechnicianDetails: null | ServiceCallTechnicianType;
  serviceCallTechnicianDetailsFetchStatus: FETCH_STATUS;
  serviceCallTechnicianDetailsPostStatus: FETCH_STATUS;
  serviceCallTechnicianDetailsPatchStatus: FETCH_STATUS;
  serviceCallTechnicianDetailsDeleteStatus: FETCH_STATUS;
  // PROVIDERS
  serviceCallProvidersList: null | PaginationType<ServiceCallProviderType>;
  serviceCallProvidersListFetchStatus: FETCH_STATUS;
  serviceCallProvidersRouteParams: RouteParamsType;
  serviceCallProviderDetails: null | ServiceCallProviderType;
  serviceCallProviderDetailsFetchStatus: FETCH_STATUS;
  serviceCallProviderDetailsPostStatus: FETCH_STATUS;
  serviceCallProviderDetailsPatchStatus: FETCH_STATUS;
  serviceCallProviderDetailsDeleteStatus: FETCH_STATUS;
  // NOTES
  serviceCallNoteDetailsPostStatus: FETCH_STATUS;
  serviceCallNoteDetailsDeleteStatus: FETCH_STATUS;
  // REQUESTS
  serviceCallRequestsList: null | PaginationType<ServiceCallRequestType>;
  serviceCallRequestsListFetchStatus: FETCH_STATUS;
  serviceCallRequestsRouteParams: RouteParamsType;
  serviceCallRequestDetails: null | ServiceCallRequestType;
  serviceCallRequestDetailsFetchStatus: FETCH_STATUS;
  serviceCallRequestDetailsDeleteStatus: FETCH_STATUS;
  serviceCallRequestDetailsPatchStatus: FETCH_STATUS;
  serviceCallRequestDetailsPostStatus: FETCH_STATUS;
}

export const initialServiceCallTechniciansRouteParams = {
  page: 1,
  limit: 10,
  order: 'technician.id',
  q: '',
  provider_id: '',
};

export const initialServiceCallProvidersRouteParams = {
  page: 1,
  limit: 10,
  order: 'provider.id',
  q: '',
};

export const initialServiceCallRequestsRouteParams = {
  page: 1,
  limit: 10,
  order: 'request.id',
  q: '',
};

const INIT_STATE: ServiceCallState = {
  // TECHNICIANS
  serviceCallTechniciansList: null,
  serviceCallTechniciansListFetchStatus: FETCH_STATUS.NULL,
  serviceCallTechniciansRouteParams: initialServiceCallTechniciansRouteParams,
  serviceCallTechnicianDetails: null,
  serviceCallTechnicianDetailsFetchStatus: FETCH_STATUS.NULL,
  serviceCallTechnicianDetailsDeleteStatus: FETCH_STATUS.NULL,
  serviceCallTechnicianDetailsPatchStatus: FETCH_STATUS.NULL,
  serviceCallTechnicianDetailsPostStatus: FETCH_STATUS.NULL,
  // PROVIDES
  serviceCallProvidersList: null,
  serviceCallProvidersListFetchStatus: FETCH_STATUS.NULL,
  serviceCallProvidersRouteParams: initialServiceCallProvidersRouteParams,
  serviceCallProviderDetails: null,
  serviceCallProviderDetailsFetchStatus: FETCH_STATUS.NULL,
  serviceCallProviderDetailsDeleteStatus: FETCH_STATUS.NULL,
  serviceCallProviderDetailsPatchStatus: FETCH_STATUS.NULL,
  serviceCallProviderDetailsPostStatus: FETCH_STATUS.NULL,
  // NOTES
  serviceCallNoteDetailsPostStatus: FETCH_STATUS.NULL,
  serviceCallNoteDetailsDeleteStatus: FETCH_STATUS.NULL,
  // REQUESTS
  serviceCallRequestsList: null,
  serviceCallRequestsListFetchStatus: FETCH_STATUS.NULL,
  serviceCallRequestsRouteParams: initialServiceCallRequestsRouteParams,
  serviceCallRequestDetails: null,
  serviceCallRequestDetailsFetchStatus: FETCH_STATUS.NULL,
  serviceCallRequestDetailsDeleteStatus: FETCH_STATUS.NULL,
  serviceCallRequestDetailsPatchStatus: FETCH_STATUS.NULL,
  serviceCallRequestDetailsPostStatus: FETCH_STATUS.NULL,
};

export const serviceCallSlice = createSlice({
  name: 'serviceCall',
  initialState: INIT_STATE,
  reducers: {
    setServiceCallTechniciansList: (state, action) => {
      state.serviceCallTechniciansList = action.payload;
    },
    setServiceCallTechniciansListFetchStatus: (state, action) => {
      state.serviceCallTechniciansListFetchStatus = action.payload;
    },
    setServiceCallTechnicianDetails: (state, action) => {
      state.serviceCallTechnicianDetails = action.payload;
    },
    setServiceCallTechnicianDetailsFetchStatus: (state, action) => {
      state.serviceCallTechnicianDetailsFetchStatus = action.payload;
    },
    setServiceCallTechnicianDetailsPostStatus: (state, action) => {
      state.serviceCallTechnicianDetailsPostStatus = action.payload;
    },
    setServiceCallTechnicianDetailsPatchStatus: (state, action) => {
      state.serviceCallTechnicianDetailsPatchStatus = action.payload;
    },
    setServiceCallTechnicianDetailsDeleteStatus: (state, action) => {
      state.serviceCallTechnicianDetailsDeleteStatus = action.payload;
    },
    setServiceCallProvidersList: (state, action) => {
      state.serviceCallProvidersList = action.payload;
    },
    setServiceCallProvidersListFetchStatus: (state, action) => {
      state.serviceCallProvidersListFetchStatus = action.payload;
    },
    setServiceCallProviderDetails: (state, action) => {
      state.serviceCallProviderDetails = action.payload;
    },
    setServiceCallProviderDetailsFetchStatus: (state, action) => {
      state.serviceCallProviderDetailsFetchStatus = action.payload;
    },
    setServiceCallProviderDetailsPostStatus: (state, action) => {
      state.serviceCallProviderDetailsPostStatus = action.payload;
    },
    setServiceCallProviderDetailsPatchStatus: (state, action) => {
      state.serviceCallProviderDetailsPatchStatus = action.payload;
    },
    setServiceCallProviderDetailsDeleteStatus: (state, action) => {
      state.serviceCallProviderDetailsDeleteStatus = action.payload;
    },
    // NOTES
    setServiceCallNoteDetailsPostStatus: (state, action) => {
      state.serviceCallNoteDetailsPostStatus = action.payload;
    },
    setServiceCallNoteDetailsDeleteStatus: (state, action) => {
      state.serviceCallNoteDetailsDeleteStatus = action.payload;
    },
    // REQUESTS
    setServiceCallRequestsList: (state, action) => {
      state.serviceCallRequestsList = action.payload;
    },
    setServiceCallRequestsListFetchStatus: (state, action) => {
      state.serviceCallRequestsListFetchStatus = action.payload;
    },
    setServiceCallRequestDetails: (state, action) => {
      state.serviceCallRequestDetails = action.payload;
    },
    setServiceCallRequestDetailsFetchStatus: (state, action) => {
      state.serviceCallRequestDetailsFetchStatus = action.payload;
    },
    setServiceCallRequestDetailsPostStatus: (state, action) => {
      state.serviceCallRequestDetailsPostStatus = action.payload;
    },
    setServiceCallRequestDetailsPatchStatus: (state, action) => {
      state.serviceCallRequestDetailsPatchStatus = action.payload;
    },
    setServiceCallRequestDetailsDeleteStatus: (state, action) => {
      state.serviceCallRequestDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'service-call-technicians') {
        state.serviceCallTechniciansRouteParams = { ...state.serviceCallTechniciansRouteParams, ...payload.data };
      }

      if (payload.reducer === 'service-call-providers') {
        state.serviceCallTechniciansRouteParams = { ...state.serviceCallProvidersRouteParams, ...payload.data };
      }

      if (payload.reducer === 'service-call-requests') {
        state.serviceCallRequestsRouteParams = { ...state.serviceCallRequestsRouteParams, ...payload.data };
      }
    });

    // CREATE SERVICE CALL TECHNICIAN DETAILS
    builder.addCase(postServiceCallTechnician.pending, state => {
      state.serviceCallTechnicianDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postServiceCallTechnician.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postServiceCallTechnician.fulfilled, state => {
      state.serviceCallTechnicianDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Technician created successfully');
      });
    });

    // CREATE SERVICE CALL TECHNICIAN DETAILS
    builder.addCase(postServiceCallNote.pending, state => {
      state.serviceCallNoteDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postServiceCallNote.rejected, (state, action) => {
      state.serviceCallNoteDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postServiceCallNote.fulfilled, state => {
      state.serviceCallNoteDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Note created successfully');
      });
    });

    // CREATE SERVICE CALL PROVIDER DETAILS
    builder.addCase(postServiceCallProvider.pending, state => {
      state.serviceCallProviderDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postServiceCallProvider.rejected, (state, action) => {
      state.serviceCallProviderDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postServiceCallProvider.fulfilled, state => {
      state.serviceCallProviderDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Provider created successfully');
      });
    });

    // GET SERVICE CALL TECHNICIANS LIST
    builder.addCase(getServiceCallTechniciansList.pending, state => {
      state.serviceCallTechniciansListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallTechniciansList.rejected, (state, action) => {
      state.serviceCallTechniciansListFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallTechniciansList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallTechniciansList.fulfilled, (state, action) => {
      state.serviceCallTechniciansListFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallTechniciansList = action.payload;
    });

    // GET SERVICE CALL TECHNICIAN DETAILS
    builder.addCase(getServiceCallTechnicianDetails.pending, state => {
      state.serviceCallTechnicianDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallTechnicianDetails.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallTechnicianDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallTechnicianDetails.fulfilled, (state, action) => {
      state.serviceCallTechnicianDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallTechnicianDetails = action.payload;
    });

    // UPDATE SERVICE CALL TECHNICIAN DETAILS
    builder.addCase(patchServiceCallTechnician.pending, state => {
      state.serviceCallTechnicianDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchServiceCallTechnician.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchServiceCallTechnician.fulfilled, state => {
      state.serviceCallTechnicianDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Technician updated successfully');
      });
    });

    // UPDATE SERVICE CALL TECHNICIAN DETAILS
    builder.addCase(deleteServiceCallTechnician.pending, state => {
      state.serviceCallTechnicianDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteServiceCallTechnician.rejected, (state, action) => {
      state.serviceCallTechnicianDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteServiceCallTechnician.fulfilled, state => {
      state.serviceCallTechnicianDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Technician deleted successfully');
      });
    });

    // UPDATE SERVICE CALL NOTE DETAILS
    builder.addCase(deleteServiceCallNote.pending, state => {
      state.serviceCallNoteDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteServiceCallNote.rejected, (state, action) => {
      state.serviceCallNoteDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteServiceCallNote.fulfilled, state => {
      state.serviceCallNoteDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Note deleted successfully');
      });
    });

    // GET SERVICE CALL PROVIDERS LIST
    builder.addCase(getServiceCallProvidersList.pending, state => {
      state.serviceCallProvidersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallProvidersList.rejected, (state, action) => {
      state.serviceCallProvidersListFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallProvidersList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallProvidersList.fulfilled, (state, action) => {
      state.serviceCallProvidersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallProvidersList = action.payload;
    });

    // GET SERVICE CALL PROVIDER DETAILS
    builder.addCase(getServiceCallProviderDetails.pending, state => {
      state.serviceCallProviderDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallProviderDetails.rejected, (state, action) => {
      state.serviceCallProviderDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallProviderDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallProviderDetails.fulfilled, (state, action) => {
      state.serviceCallProviderDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallProviderDetails = action.payload;
    });

    // UPDATE SERVICE CALL PROVIDER DETAILS
    builder.addCase(patchServiceCallProvider.pending, state => {
      state.serviceCallProviderDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchServiceCallProvider.rejected, (state, action) => {
      state.serviceCallProviderDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchServiceCallProvider.fulfilled, state => {
      state.serviceCallProviderDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Provider updated successfully');
      });
    });

    // DELETE SERVICE CALL PROVIDER DETAILS
    builder.addCase(deleteServiceCallProvider.pending, state => {
      state.serviceCallProviderDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteServiceCallProvider.rejected, (state, action) => {
      state.serviceCallProviderDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteServiceCallProvider.fulfilled, state => {
      state.serviceCallProviderDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Provider deleted successfully');
      });
    });

    // CREATE SERVICE CALL REQUEST DETAILS
    builder.addCase(postServiceCallRequest.pending, state => {
      state.serviceCallRequestDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postServiceCallRequest.rejected, (state, action) => {
      state.serviceCallRequestDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postServiceCallRequest.fulfilled, state => {
      state.serviceCallRequestDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Request created successfully');
      });
    });

    // GET SERVICE CALL REQUESTS LIST
    builder.addCase(getServiceCallRequestsList.pending, state => {
      state.serviceCallRequestsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallRequestsList.rejected, (state, action) => {
      state.serviceCallRequestsListFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallRequestsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallRequestsList.fulfilled, (state, action) => {
      state.serviceCallRequestsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallRequestsList = action.payload;
    });

    // GET SERVICE CALL REQUEST DETAILS
    builder.addCase(getServiceCallRequestDetails.pending, state => {
      state.serviceCallRequestDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceCallRequestDetails.rejected, (state, action) => {
      state.serviceCallRequestDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.serviceCallRequestDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceCallRequestDetails.fulfilled, (state, action) => {
      state.serviceCallRequestDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.serviceCallRequestDetails = action.payload;
    });

    // UPDATE SERVICE CALL REQUEST DETAILS
    builder.addCase(patchServiceCallRequest.pending, state => {
      state.serviceCallRequestDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchServiceCallRequest.rejected, (state, action) => {
      state.serviceCallRequestDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchServiceCallRequest.fulfilled, state => {
      state.serviceCallRequestDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Request updated successfully');
      });
    });

    // DELETE SERVICE CALL REQUEST DETAILS
    builder.addCase(deleteServiceCallRequest.pending, state => {
      state.serviceCallRequestDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteServiceCallRequest.rejected, (state, action) => {
      state.serviceCallRequestDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteServiceCallRequest.fulfilled, state => {
      state.serviceCallRequestDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Request deleted successfully');
      });
    });
  },
});

export const {
  setServiceCallTechniciansListFetchStatus,
  setServiceCallTechniciansList,
  setServiceCallTechnicianDetails,
  setServiceCallTechnicianDetailsFetchStatus,
  setServiceCallTechnicianDetailsPostStatus,
  setServiceCallTechnicianDetailsPatchStatus,
  setServiceCallTechnicianDetailsDeleteStatus,
  setServiceCallProvidersList,
  setServiceCallProvidersListFetchStatus,
  setServiceCallProviderDetailsDeleteStatus,
  setServiceCallProviderDetailsFetchStatus,
  setServiceCallProviderDetailsPatchStatus,
  setServiceCallProviderDetailsPostStatus,
  setServiceCallProviderDetails,
  setServiceCallNoteDetailsPostStatus,
  setServiceCallNoteDetailsDeleteStatus,
  setServiceCallRequestDetailsFetchStatus,
  setServiceCallRequestsList,
  setServiceCallRequestsListFetchStatus,
  setServiceCallRequestDetails,
  setServiceCallRequestDetailsPostStatus,
  setServiceCallRequestDetailsPatchStatus,
  setServiceCallRequestDetailsDeleteStatus,
} = serviceCallSlice.actions;

export default serviceCallSlice.reducer;
