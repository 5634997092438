import { createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import snackbarUtils from '../../utils/SnackbarUtils';
import { deleteAddressDetails } from './addresses.action';

export interface AddressesState {
  addressDeleteStatus: FETCH_STATUS;
}

const INIT_STATE: AddressesState = {
  addressDeleteStatus: FETCH_STATUS.NULL,
};

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState: INIT_STATE,
  reducers: {
    setAddressDeleteStatus: (state, action) => {
      state.addressDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    // DELETE ADDRESS
    builder.addCase(deleteAddressDetails.pending, state => {
      state.addressDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteAddressDetails.rejected, (state, action) => {
      state.addressDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteAddressDetails.fulfilled, state => {
      state.addressDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Address deleted successfully');
      });
    });
  },
});

export const { setAddressDeleteStatus } = addressesSlice.actions;
export default addressesSlice.reducer;
